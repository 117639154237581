import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Paper, Button, useTheme } from '@mui/material'; // <-- Add these imports

// Define the EditStory functional component
const EditStory = () => {
  // Destructure id from useParams
  const { id } = useParams();
  // Call useNavigate to access the history object
  let navigate = useNavigate();
  
  // Your theme variable
  const theme = useTheme(); // <-- Use theme
  
  // Declare state variables for the story, rows, storyLength and question
  const [story, setStory] = useState('');
  const [rows, setRows] = useState(4);
  const [storyLength, setStoryLength] = useState(0);
  const [question, setQuestion] = useState('');
  // Add a new state variable for the id of the question of the day
  const [questionId, setQuestionId] = useState('');

  // Fetch the question of the day from the backend
  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/question-of-the-day`);
        setQuestion(response.data.content); // Update this line
        setQuestionId(response.data._id);  // Keep this line as it is
      } catch (error) {
        console.error("Fetching question failed:", error);
      }
    };
    // Call the fetchQuestion function
    fetchQuestion();
  }, []);

  // Fetch the current story from the backend
  useEffect(() => {
    const fetchStory = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/story/${id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const storyData = await response.json();
        setStory(storyData.content);
        setStoryLength(storyData.content.length); // Update the storyLength state variable
        if (storyData.content.length > 280 && rows < 16) {
          setRows(rows + 12);
        }
      } catch (error) {
        console.error("Fetching story failed:", error);
      }
    };
    // Call the fetchStory function
    fetchStory();
  }, [id, rows]); // Add rows as a dependency

  // Define a function that updates the story state variable with the current value of the textarea
  const handleStoryChange = (event) => {
    if (event.target.value.length > 1000) return;
    setStory(event.target.value);
    setStoryLength(event.target.value.length); // Update the storyLength state variable
    if (event.target.value.length > 280 && rows < 16) {
      setRows(rows + 12);
    }
  };

  // Define a function that handles submission of the form
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/story/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          content: story, // send the story
          questionId: questionId // send the question id
         }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      navigate(`/story/${id}`);
    } catch (error) {
      console.error("Updating story failed:", error);
    }
  };

  // Render the component
  return (
    <div>
      <h1>Current Game</h1>
      <h2>{question}</h2>
      <form onSubmit={handleSubmit}>
        <Paper 
          component="div" 
          style={{ 
            width: '100%', 
            padding: '16px', 
            margin: '16px 0',
            backgroundColor: theme.palette.background.paper
          }}
        >
          <textarea 
            value={story} 
            onChange={handleStoryChange} 
            rows={rows} 
            placeholder="Edit your story here..."
            style={{ 
              width: '100%', 
              fontSize: '1rem', 
              fontFamily: theme.typography.fontFamily, 
              lineHeight: theme.typography.body1.lineHeight,
              letterSpacing: theme.typography.body1.letterSpacing,
              boxSizing: 'border-box' // to make sure padding and border are included in the width
            }}
          />
        </Paper>
        <p>{storyLength}/1000</p> {/* Display the story length */}
        <Button 
          variant="contained" 
          color="primary" 
          type="submit"
        >
          Submit
        </Button>
      </form>
    </div>
  );
};

// Export the EditStory component
export default EditStory;
