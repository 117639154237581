/* We have designed the login to be simple for the user but secure.

Here is how the login flow works:

Sending the magic link: In Login.js, when the user submits their email, 
the handleLogin function is triggered. This function makes a call to the 
Firebase Cloud Function sendMagicLink with the user's email. 
This cloud function generates a sign-in link using Firebase Authentication 
and sends this link to the user's email via SendGrid. 
The link is structured in a way that, when clicked, it redirects to your 
application (the URL specified in the actionCodeSettings of the cloud function).

Receiving the magic link: When the user clicks on the magic link in their email, 
they are redirected to your application with a long URL. This URL contains a 
one-time code that Firebase uses to sign in the user. 
The URL is structured like this: https://your-app.com/?emailLink=long-url-with-code.

Signing in the user: Now, when your application starts, it needs to handle this 
incoming URL and sign in the user. This is done in AuthContext.js inside the 
useEffect hook. The isSignInWithEmailLink function checks if the current URL 
is a sign-in with email link. If it is, the signInWithEmailLink function is called 
with the URL and the user's email. This function will exchange the one-time code 
in the URL with Firebase Authentication to sign in the user. After the user is 
signed in, the onAuthStateChanged function will trigger an event, notifying your 
application that the user's sign-in state has changed.

Setting the current user: Once the user is signed in, the onAuthStateChanged 
function in AuthContext.js will trigger and call the setCurrentUser function with 
the signed-in user. This will cause your application to re-render any components 
that depend on the currentUser context value, effectively "logging in" 
the user in your application.

The "magic" of the magic link lies in the fact that the link contains 
a one-time code that Firebase Authentication recognizes and uses to sign in the user. 
This code is tied to the user's email and is valid only for 
a single use, enhancing the security of the sign-in process. */


import React, { useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from './AuthContext'; // Import the useAuth hook from AuthContext.js
import { useLocation } from 'react-router-dom'; // Add this import at the top
import { useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Import the Link component
import Typography from '@mui/material/Typography'; // Import the Typography component
import CircularProgress from '@mui/material/CircularProgress'; // Add this import at the top


function Login() {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const paramEmail = urlParams.get('email') ? decodeURIComponent(urlParams.get('email')) : '';
  const [email, setEmail] = useState(paramEmail); // Set the initial state to the fetched emails
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { currentUser, role, handleLogout } = useAuth(); // Fetch currentUser and role from the AuthContext

  // If the user is authenticated, log their role for debugging purposes
  if(currentUser) {
    console.log("User role:", role);
  }

  const auth = getAuth();

  useEffect(() => {
    if (currentUser) {
      // Replace this URL with your actual server URL if it's running on a different port or domain
      const serverUrl = process.env.REACT_APP_API_URL;
  
      axios.get(`${serverUrl}/users/${currentUser.uid}`)
        .then(res => {
          // If the user does not exist in MongoDB, create a new document
          if (res.status === 404) {
            axios.post(`${serverUrl}/signup`, {
              uid: currentUser.uid,
              email: currentUser.email,
              username: currentUser.displayName,
            })
              .then(() => console.log('User document created'))
              .catch(err => console.error('Error creating user document:', err));
          }
        })
        .catch(err => {
          console.error('Error fetching user document:', err);
          
          // Verify that the error is a 404 (user not found)
          if (err.response && err.response.status === 404) {
            axios.post(`${serverUrl}/signup`, {
              uid: currentUser.uid,
              email: currentUser.email,
              username: currentUser.displayName,
            })
            .then(() => console.log('User document created'))
            .catch(err => console.error('Error creating user document:', err));
          }
        });
    }
  }, [currentUser]); // Re-run this effect when currentUser changes

  // Function to validate an email address
  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  // Function to handle Snackbar close event
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // Function to handle login event
  const handleLogin = (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");
    setMessage("");

    if (!validateEmail(email)) {
      setError("Invalid email address");
      setLoading(false);
      return;
    }

    let actionCodeSettings = {
      url: `${window.location.origin}/submit-story?email=${encodeURIComponent(email)}`, // Dynamic return URL based on the current domain
      handleCodeInApp: true,
    };

    const functions = getFunctions(auth.app); 
    const sendMagicLink = httpsCallable(functions, 'sendMagicLink');

    sendMagicLink({ email, actionCodeSettings }) // Pass actionCodeSettings to the cloud function
      .then((result) => {
        console.log(result.data.status); 
        window.localStorage.setItem('emailForSignIn', email);
        setMessage(`Magic link sent to ${email}. Check your inbox.`);
        setLoading(false);
      })
      .catch((error) => {
        console.error(`Error sending magic link: ${error}`);
        setError("Failed to send sign in link. Please try again later.");
        setLoading(false);
      });

    setOpen(true);
  };

  // Return JSX for Login component
  return (
    <div>
      {currentUser ? (
        <div>
          {/* Display logged-in user's email and their role */}
          <p>Email: {currentUser.email}</p>
          <p>Role: {role}</p>
          <Button variant="contained" color="primary" onClick={handleLogout}>
            Logout
          </Button>
        </div>
      ) : (
        <form onSubmit={handleLogin} className="waitlist-form">
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            type="email"
            required
            fullWidth
          />
          <Button variant="contained" color="primary" type="submit" disabled={loading}>
            Send Magic Link
          </Button>

          {/* Add links to Terms of Service and Privacy Policy */}
          <Typography variant="body2" color="textSecondary" align="left">
            By signing in you agree to our{' '}
            <Link to="/terms-of-service" style={{ color: 'inherit', textDecoration: 'none' }}>
              Terms of Service
            </Link>
            {' and '}
            <Link to="/privacy-policy" style={{ color: 'inherit', textDecoration: 'none' }}>
              Privacy Policy
            </Link>.
          </Typography>
        </form>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={loading ? <CircularProgress size={24} /> : (error ? error : message)}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}

export default Login;
