// SetSeason.js
import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CustomSnackbar from './CustomSnackbar'; // replace './CustomSnackbar' with the correct path to your CustomSnackbar component

const SetSeason = () => {
  const [selectedSeason, setSelectedSeason] = useState("");
  const [seasons, setSeasons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmChange, setConfirmChange] = useState(false);
  const [addSeasonDialogOpen, setAddSeasonDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/settings`)
      .then(response => response.json())
      .then(data => setSeasons(data.seasons));
  }, []); 

  const handleChange = (e) => {
    setSelectedSeason(e.target.value);
  };

  const handleConfirmation = (e) => {
    e.preventDefault();
    if (selectedSeason !== "") {
      setConfirmChange(true);
    } else {
      setSnackbarMessage('Please select a season.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
    }
  };

  const handleAddSeason = () => {
    setAddSeasonDialogOpen(true);
  };

  const handleAddSeasonDialogClose = async (confirm) => {
    if (confirm) {
      const newSeason = getNextSeasonNumber();
      const updatedSeasons = [...seasons, newSeason];

      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/seasons`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ seasons: updatedSeasons }),
        });

        if (response.ok) {
          setSnackbarMessage(`Season ${newSeason} has been successfully created.`);
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          setSeasons(updatedSeasons);
          setSelectedSeason(newSeason);
        } else {
          setSnackbarMessage('There was an error creating the new season.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      } catch (error) {
        setSnackbarMessage('There was an error creating the new season.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      setLoading(false);
    }
    setAddSeasonDialogOpen(false);
  };

  const getNextSeasonNumber = () => {
    return Math.max(...seasons) + 1;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedSeason !== "") {
      setLoading(true);

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/settings`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ currentSeason: selectedSeason }),
        });

        if (response.ok) {
          setSnackbarMessage('Season successfully changed to ' + selectedSeason);
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          setConfirmChange(false);
          setLoading(false);
          window.location.reload();  // Add this line to reload the page
        } else {
          setSnackbarMessage('There was an error setting the season.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setConfirmChange(false);
          setLoading(false);
        }
      } catch (error) {
        setSnackbarMessage('There was an error setting the season.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setConfirmChange(false);
        setLoading(false);
      }
    } else {
      setSnackbarMessage('Please select a season.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
    }
  };

  const handleCancel = () => {
    setSelectedSeason("");
    setConfirmChange(false);
  };

  return (
    <>
      {!confirmChange ? (
        <form onSubmit={handleConfirmation}>
          <FormControl fullWidth>
            <InputLabel id="season-label">Season</InputLabel>
            <Select
              labelId="season-label"
              id="season"
              value={selectedSeason}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>-- Select Season --</em>
              </MenuItem>
              {seasons.map((s, index) => (
                <MenuItem key={index} value={s}>
                  {s}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button 
            type="submit" 
            variant="contained" 
            color="primary" 
            disabled={loading || selectedSeason === ""}
          >
            Change Current Season
          </Button>
        </form>
      ) : (
        <div>
        <p>WARNING: Are you sure you want to change the season? 
            Do NOT do this if you are in the midde of a live season. 
            It will reset that season for all players.</p>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleCancel}
            >
              Cancel
            </Button>

            <Button 
              variant="outlined" 
              color="secondary" 
              onClick={handleSubmit} 
              disabled={loading}
            >
              I am sure
            </Button>
        </div>
      )}
    <Button
        variant="outlined"
        color="secondary"
        onClick={handleAddSeason}
        startIcon={<AddIcon />}
        disabled={loading}
      >
        New Season
    </Button>  

    <Dialog
      open={addSeasonDialogOpen}
      onClose={handleAddSeasonDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirm New Season Creation"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Season ${seasons.join(', ')} are already created. Would you like to create season ${getNextSeasonNumber()}?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleAddSeasonDialogClose(false)} color="primary">
          No
        </Button>
        <Button onClick={() => handleAddSeasonDialogClose(true)} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>  
    <CustomSnackbar 
      open={snackbarOpen} 
      handleClose={() => setSnackbarOpen(false)} 
      message={snackbarMessage} 
      severity={snackbarSeverity}
    />
    </>
  );
};

export default SetSeason;
