import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { generateUsernames } from './usernames.js';
import axios from 'axios';

function UsernameSelector({ selectedUsername, onUsernameSelect }) {
  const [usernameOptions, setUsernameOptions] = useState([]);

  useEffect(() => {
    const fetchUsernames = async () => {
      try {
        const existingUsernamesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/usernames`);
        const existingUsernames = existingUsernamesResponse.data;

        const generatedUsernames = generateUsernames().filter(
          username => !existingUsernames.includes(username)
        );

        setUsernameOptions(generatedUsernames);
      } catch (error) {
        console.error('Error fetching usernames:', error);
      }
    };

    fetchUsernames();
  }, []);

  const handleSelectUsername = (username) => {
    onUsernameSelect(username);
  };

  const generateNewUsernames = async () => {
    try {
      const existingUsernamesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/usernames`);
      const existingUsernames = existingUsernamesResponse.data;

      const generatedUsernames = generateUsernames().filter(
        username => !existingUsernames.includes(username)
      );

      setUsernameOptions(generatedUsernames);
    } catch (error) {
      console.error('Error fetching usernames:', error);
    }
  };

  return (
    <>
      <ButtonGroup variant="outlined" color="primary" aria-label="outlined primary button group">
        {usernameOptions.map((usernameRec, index) => (
          <Button 
            key={index}
            onClick={() => handleSelectUsername(usernameRec)}
            color={selectedUsername === usernameRec ? "secondary" : "primary"}
          >
            {usernameRec}
          </Button>
        ))}
      </ButtonGroup>
      <Button variant="contained" color="secondary" onClick={generateNewUsernames}>
        Generate New Usernames
      </Button>
    </>
  );
}

export default UsernameSelector;
