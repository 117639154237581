import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useSortableData } from './hooks/useSortableData';

const Players = () => {
  const [playersData, setPlayersData] = useState([]);
  const { items, requestSort, sortConfig } = useSortableData(playersData, 'username', 'asc');

  useEffect(() => {
    const fetchPlayersData = async () => {
      try {
        const [usersResponse, storiesResponse, votesResponse] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/allusers`),
          fetch(`${process.env.REACT_APP_API_URL}/user-stories-count`),
          fetch(`${process.env.REACT_APP_API_URL}/vote/user-votes-count`)
        ]);

        const users = await usersResponse.json();
        const storiesCount = await storiesResponse.json();
        const votesCount = await votesResponse.json();

        const data = users.map(user => ({
          ...user,
          stories: storiesCount.find(story => story._id === user.uid)?.count || 0,
          votes: votesCount.find(vote => vote._id === user.uid)?.count || 0
        }));

        setPlayersData(data);
      } catch (error) {
        console.error('Error fetching players:', error);
      }
    };

    fetchPlayersData();
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('UID copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy UID to clipboard:', err);
    });
  };
  
  return (
    <div>
      <p>Data is for all players across all seasons</p> {/* Added this line */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ paddingRight: 0.5, paddingLeft: 0.5, width: '20%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} sortDirection={sortConfig.key === 'username' ? sortConfig.direction : false}>
                <Button onClick={() => requestSort('username')} endIcon={<UnfoldMoreIcon />}>
                  Username
                </Button>
              </TableCell>
              <TableCell align="right" sx={{ width: '10%', paddingRight: 0.5, paddingLeft: 0.5 }}>
                <Button onClick={() => requestSort('uid')} endIcon={<UnfoldMoreIcon />}>
                  UID
                </Button>
              </TableCell>
              <TableCell align="right" sx={{ width: '10%', paddingRight: 0.5, paddingLeft: 0.5 }}>
                <Button onClick={() => requestSort('firstTimeUser')} endIcon={<UnfoldMoreIcon />}>
                  First Time
                </Button>
              </TableCell>
              <TableCell align="right" sx={{ width: '10%', paddingRight: 0.5, paddingLeft: 0.5 }}>
                <Button onClick={() => requestSort('location')} endIcon={<UnfoldMoreIcon />}>
                  Location
                </Button>
              </TableCell>
              <TableCell align="right" sx={{ width: '10%', paddingRight: 0.5, paddingLeft: 0.5 }}>
                <Button onClick={() => requestSort('stories')} endIcon={<UnfoldMoreIcon />}>
                  Stories
                </Button>
              </TableCell>
              <TableCell align="right" sx={{ width: '10%', paddingRight: 0.5, paddingLeft: 0.5 }}>
                <Button onClick={() => requestSort('votes')} endIcon={<UnfoldMoreIcon />}>
                  Votes
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row) => (
              <TableRow key={row.uid}>
                <TableCell sx={{ paddingRight: 0.5, paddingLeft: 1.5, width: '20%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {row.username}
                </TableCell>
                <TableCell sx={{ width: '10%', paddingRight: 0.5, paddingLeft: 0.5 }} align="center">
                  <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => copyToClipboard(row.uid)}>
                    <FileCopyIcon sx={{ fontSize: '0.875rem', color: 'primary.main' }} />
                    <span style={{ color: 'primary' }}>{row.uid.substring(0, 5)}...</span>
                  </div>
                </TableCell>
                <TableCell sx={{ width: '10%', paddingRight: 0.5, paddingLeft: 0.5 }} align="center">{row.firstTimeUser ? 'Y' : 'N'}</TableCell>
                <TableCell sx={{ width: '10%', paddingRight: 0.5, paddingLeft: 0.5 }} align="center">{row.location}</TableCell>
                <TableCell sx={{ width: '10%', paddingRight: 0.5, paddingLeft: 0.5 }} align="center">{row.stories}</TableCell>
                <TableCell sx={{ width: '10%', paddingRight: 0.5, paddingLeft: 0.5 }} align="center">{row.votes}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Players;
