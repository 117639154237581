// custom hook to handle the sorting logic
// initially used for the season standings table

import { useMemo, useState } from 'react';

export function useSortableData(items, initialSortKey = 'gold', initialSortOrder = 'desc') {
  const [sortConfig, setSortConfig] = useState({
    key: initialSortKey,
    direction: initialSortOrder,
  });

  const sortedItems = useMemo(() => {
    const sortableItems = [...items];
    sortableItems.sort((a, b) => {
      const aValue = a[sortConfig.key] || ''; // Default to empty string if undefined
      const bValue = b[sortConfig.key] || ''; // Default to empty string if undefined

      // Handle string comparison in a case-insensitive manner
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue) * (sortConfig.direction === 'asc' ? 1 : -1);
      }

      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
}
