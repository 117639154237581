// Questions.js

import React, { useState, useEffect } from 'react';
import { Typography, List, ListItem, ListItemText, Button, TextField, Select, MenuItem, IconButton, ListItemSecondaryAction } from '@mui/material';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material'; // Import Edit icon
import { Delete as DeleteIcon } from '@mui/icons-material';  // For the "Delete" icon
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import CustomSnackbar from './CustomSnackbar';

const STATUSES = ['staged', 'submission', 'voting', 'results']; // Statuses enum

const Questions = () => {
  const [questions, setQuestions] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editingStatusIndex, setEditingStatusIndex] = useState(null); // Index for editing status
  const [editedQuestions, setEditedQuestions] = useState([]);
  const [currentSeason, setCurrentSeason] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [maxQuestionsPerSeason, setMaxQuestionsPerSeason] = useState(null); // New state variable

  useEffect(() => {
    // Fetch current season
    fetch(`${process.env.REACT_APP_API_URL}/settings/season`)
      .then(response => response.json())
      .then(data => setCurrentSeason(data.currentSeason))
      .catch(err => console.error(err));
  
    // Fetch questions for the current season
    fetch(`${process.env.REACT_APP_API_URL}/questions/current-season`)
      .then(response => response.json())
      .then(data => {
        setQuestions(data);
        setEditedQuestions([...data]);
      })
      .catch(err => console.error(err));
  
    // Fetch the maximum number of questions allowed per season
    fetch(`${process.env.REACT_APP_API_URL}/settings`)
      .then(response => response.json())
      .then(data => setMaxQuestionsPerSeason(data.questionsPerSeason))
      .catch(err => console.error(err));
  }, []);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
    setEditedQuestions([...questions]);
  };

  const handleChange = (index) => (e) => {
    const newQuestions = [...editedQuestions];
    newQuestions[index].content = e.target.value;
    newQuestions[index].sequence = index + 1; // Update sequence based on index position
    setEditedQuestions(newQuestions);
  };

  /*Update for new add question button.
  in the handleSubmit function, we need to differentiate between 
  updating existing questions and creating a new one based on 
  the _id field of the question. If _id is empty, it means this question 
  is new, and we need to POST it to the /questions endpoint. 
  If _id is not empty, we will update it as usual: */

  const handleSubmit = async () => {
    try {
      const responses = await Promise.all(
        editedQuestions.map((question, index) =>
          question.tempId ?
            fetch(`${process.env.REACT_APP_API_URL}/questions`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                content: question.content,
                season: currentSeason,  // You may need to replace this with the actual current season
                sequence: index + 1, // Send new sequence
                status: question.status
              }),
            })
          :
            fetch(`${process.env.REACT_APP_API_URL}/questions/content/${question._id}`, {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ 
                content: question.content,
                sequence: index + 1 // Send new sequence
              }),
            })
        )
      );
  
      if (responses.every((response) => response.ok)) {
        const updatedQuestions = await Promise.all(responses.map((response) => response.json()));
        setSnackbarMessage('Questions successfully updated.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setQuestions(updatedQuestions);
        setEditedQuestions([...updatedQuestions]);
        setEditing(false);
      } else {
        setSnackbarMessage('There was an error updating the questions.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error(error);
      setSnackbarMessage('There was an error updating the questions.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleNewQuestion = async () => {
    const newQuestion = {
      tempId: uuidv4(),
      sequence: editedQuestions.length + 1,
      status: 'staged',
      content: ''
    };
  
    setEditedQuestions([...editedQuestions, newQuestion]);
    setEditing(true);
  };

  const handleDelete = async (id, index) => {
    if (window.confirm('Are you sure you want to delete this question?')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/questions/${id}`, {
          method: 'DELETE',
        });
    
        if (response.ok) {
          setSnackbarMessage('Question successfully deleted.');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
  
          // Refetch the questions (with the new order)
          fetch(`${process.env.REACT_APP_API_URL}/questions/current-season`)
            .then(response => response.json())
            .then(data => {
              setQuestions(data);
              setEditedQuestions([...data]);
            })
            .catch(err => console.error(err));
        } else {
          setSnackbarMessage('There was an error deleting the question.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      } catch (error) {
        console.error(error);
        setSnackbarMessage('There was an error deleting the question.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };
  
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
  
    const items = Array.from(editedQuestions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  
    // This is where we'll capture the updated sequence order
    const updatedSequenceItems = items.map((item, index) => ({...item, sequence: index + 1}));
  
    setEditedQuestions(updatedSequenceItems);
  
    // Now we will make API calls to update the sequence in the database
    try {
      const responses = await Promise.all(
        updatedSequenceItems.map((question, index) =>
          fetch(`${process.env.REACT_APP_API_URL}/questions/content/${question._id}`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
              sequence: index + 1 // Send new sequence
            }),
          })
        )
      );
  
      if (!responses.every((response) => response.ok)) {
        setSnackbarMessage('There was an error updating the questions.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error(error);
      setSnackbarMessage('There was an error updating the questions.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleStatusEdit = (index) => {
    setEditingStatusIndex(index);
  };

  const handleStatusChange = (index, status) => {
    const updatedQuestions = [...editedQuestions];
    updatedQuestions[index].status = status;
    setEditedQuestions(updatedQuestions);
    setEditingStatusIndex(null);
    
    // Update the status in the database
    fetch(`${process.env.REACT_APP_API_URL}/questions/${updatedQuestions[index]._id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status }),
    })
    .then(response => response.json())
    .then(() => {
      setSnackbarMessage('Status successfully updated.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    })
    .catch(err => {
      console.error(err);
      setSnackbarMessage('There was an error updating the status.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    });
  };

  const truncateSSID = (ssid) => {
    if (!ssid) return null;
    return `${ssid.slice(0, 4)}...${ssid.slice(-4)}`;
  };  
  
  const isAddNewDisabled = () => {
    return editedQuestions.length >= maxQuestionsPerSeason;
  };

   return (
    <div>
      <h2>Current Season Questions</h2>
      {editing ? (
      <div>
        <Button variant="contained" color="secondary" onClick={handleCancel}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
      </div>
    ) : (
      <div>
        <Button variant="contained" color="primary" onClick={handleEdit}>Edit</Button>
        <Button 
          variant="contained" 
          color="secondary" 
          onClick={handleNewQuestion} 
          startIcon={<AddIcon />} 
          disabled={isAddNewDisabled()} // Disable based on the number of questions
        >
          New Question
        </Button>
      </div>
    )}
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="questions">
          {(provided) => (
            <List {...provided.droppableProps} ref={provided.innerRef}>
              {(editing ? editedQuestions : questions).map((question, index) => (
                <Draggable key={question._id || question.tempId} draggableId={question._id || question.tempId} index={index}>
                  {(provided) => (
                    <ListItem 
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <ListItemText
                        primary={editing ? (
                          <div>
                            <Typography variant="body1" component="span">{`Q${question.sequence}: `}</Typography>
                            <TextField
                              fullWidth  // Full-width text field
                              value={editedQuestions[index].content}
                              onChange={handleChange(index)}
                              InputProps={{style: {fontFamily: 'inherit'}}}  // Inherits font-family from parent
                            />
                          </div>
                        ) : (
                          `Q${question.sequence}: ${question.content}`
                        )}
                        secondary={
                          <React.Fragment>
                            {/* // TO DO ADDING THIS HAS INTRODUCED A WARNING */}
                            {/* // When the edit icon is clicked these warnings appear:
                            Warning: validateDOMNesting(...): <fieldset> cannot appear as a descendant of <p>
                            Warning: validateDOMNesting(...): <div> cannot appear as a descendant of <p> 
                            Unable to resolve after 2hrs of troubleshooting.
                            Bizarrely just adding this comment has resolved the issue. 
                            Leaving for now as functionality still works as expected. May need to resolve in the future.*/}
                            <Typography component="span" variant="body2" color="textSecondary">
                              {`Status: `}
                              {editingStatusIndex === index ? (
                                <Select
                                  value={question.status}
                                  onChange={(e) => handleStatusChange(index, e.target.value)}
                                  style={{ minWidth: 100 }}
                                  autoFocus // Automatically focus and open the dropdown
                                >
                                  {STATUSES.map((status) => (
                                    <MenuItem key={status} value={status}>{status}</MenuItem>
                                  ))}
                                </Select>
                              ) : (
                                <>
                                  {question.status}
                                  <IconButton edge="end" aria-label="edit" onClick={() => handleStatusEdit(index)} >
                                    <EditIcon style={{ fontSize: '100%' }} />
                                  </IconButton>
                                </>
                              )}
                            </Typography>
                            <br />
                            <Typography component="span" variant="body2" color="textSecondary">
                              {`submissionEmailSSID: `}
                              {question.submissionEmailSSID ?
                                <a href={`https://mc.sendgrid.com/single-sends/${question.submissionEmailSSID}/stats/preview`} target='_blank' rel='noreferrer noopener'>{truncateSSID(question.submissionEmailSSID)}</a>
                                :
                                <Typography component="span" variant="body2" color="error">NONE</Typography>
                              }
                            </Typography>
                            <br />
                            <Typography component="span" variant="body2" color="textSecondary">
                              {`votingEmailSSID: `}
                              {question.votingEmailSSID ?
                                <a href={`https://mc.sendgrid.com/single-sends/${question.votingEmailSSID}/stats/preview`} target='_blank' rel='noreferrer noopener'>{truncateSSID(question.votingEmailSSID)}</a>
                                :
                                <Typography component="span" variant="body2" color="error">NONE</Typography>
                              }
                            </Typography>
                            <br />
                            <Typography component="span" variant="body2" color="textSecondary">
                              {`resultsEmailSSID: `}
                              {question.resultsEmailSSID ?
                                <a href={`https://mc.sendgrid.com/single-sends/${question.resultsEmailSSID}/stats/preview`} target='_blank' rel='noreferrer noopener'>{truncateSSID(question.resultsEmailSSID)}</a>
                                :
                                <Typography component="span" variant="body2" color="error">NONE</Typography>
                              }
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      {editing && (
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(question._id, index)}>
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
      <CustomSnackbar 
        open={snackbarOpen} 
        handleClose={() => { setSnackbarOpen(false); setEditing(false); }} 
        message={snackbarMessage} 
        severity={snackbarSeverity}
      />
    </div>
  );
};

export default Questions;

