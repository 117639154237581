// MyActivity.js
import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import { CircularProgress, Box, Typography } from '@mui/material';
import usePageContent from './hooks/usePageContent';

function MyActivity() {
  const { currentUser } = useContext(AuthContext);
  const [activities, setActivities] = useState([]);
  const { content, loading: contentLoading } = usePageContent('MyActivity');
  const [loading, setLoading] = useState(true);
  const [currentSeason, setCurrentSeason] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/activity/${currentUser.uid}`);
        setActivities(res.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetch(`${process.env.REACT_APP_API_URL}/settings/season`)
      .then(response => response.json())
      .then(data => setCurrentSeason(data.currentSeason || 0))
      .catch(err => console.error(err));

    fetchData();
  }, [currentUser]);

  const groupBySeason = activities.reduce((acc, activity) => {
    const season = activity.season !== undefined ? activity.season : (currentSeason !== null ? currentSeason : 'Unknown');
    acc[season] = acc[season] || [];
    acc[season].push(activity);
    return acc;
  }, {});

  // Sort activities by question sequence within each season
  Object.keys(groupBySeason).forEach(season => {
    groupBySeason[season].sort((a, b) => a.sequence - b.sequence);
  });

  if (loading || contentLoading) {
    return <CircularProgress />;
  }

  return (
    <div>
      {content.h1.map((header, index) => <h1 key={index}>{header}</h1>)}
      {activities.length === 0 
        ? content.p.map((paragraph, index) => <p key={index}>{paragraph}</p>)
        : Object.keys(groupBySeason).map((season, index) => (
            <Box key={index}>
              <h2>Season {season}</h2>
              {groupBySeason[season].map((activity, i) => (
                <Box key={i}>
                  <h3>S{activity.season}Q{activity.sequence}: {activity.questionContent}</h3>
                  <Typography variant="subtitle1">Your story: {activity.storyContent}</Typography>
                  {/* Loop through all voted stories */}
                  {activity.voteContents.map((voteContent, j) => (
                    <Typography key={j} variant="subtitle1">Your vote {j+1}: {voteContent}</Typography>
                  ))}
                </Box>
              ))}
            </Box>
          ))}
    </div>
  );
}

export default MyActivity;
