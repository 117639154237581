// component that will render each choice as a button with incremented letter before the choice text.

/* 
The MultipleChoiceButtons component receives choices as a prop which is an array of choices. 
It also receives onClick as a prop which is a function to be executed when a button is clicked. 
For each choice, it creates a Button with a letter before the choice text. 
The letter is derived from the ASCII value of A (65) plus the index of the choice.
*/

import React from 'react';
import { Button } from '@mui/material';

const MultipleChoiceButtons = ({ choices, onClick }) => {
  const handleClick = (choice, index) => {
    onClick && onClick(choice, index);
  };

  return (
    <div>
      {choices.map((choice, index) => (
        <Button
          key={index}
          variant="contained"
          color="primary"
          style={{ width: '100%', marginBottom: 8 }} 
          onClick={() => handleClick(choice, index)}
        >
          {`${String.fromCharCode(65 + index)}. ${choice}`}
        </Button>
      ))}
    </div>
  );
};

export default MultipleChoiceButtons;
