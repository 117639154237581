// Global css styles for the app.

import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// Create a theme instance.
let theme = createTheme({
  palette: {
    primary: {
      main: '#54b6c1',
    },
    secondary: {
      main: '#F1bb3f',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: 'Poppins, Lato, Open Sans, sans-serif',
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#F1bb3f',
          },
        },
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          fontFamily: 'Poppins, Lato, Open Sans, sans-serif',
          padding: '0 16px', // Add padding here
        },
        p: {
          fontFamily: 'Poppins, Lato, Open Sans, sans-serif',
        }
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
