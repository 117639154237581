// HTMLField.js
// Use React Quill to allow viewing and editing of HTML content
// Added initially for the Page Content feature for SuperAdmins

import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles

const HTMLField = ({ name, label, value, onChange }) => {
  return (
    <div>
      <label>{label}</label>
      <ReactQuill 
        value={value || ''}
        onChange={(content, delta, source, editor) => {
          onChange({
            target: { 
              name: name, 
              value: editor.getHTML() 
            }
          });
        }}
      />
    </div>
  );
}

export default HTMLField;
