import React, { useState, useContext } from 'react';
import { AuthContext } from './AuthContext';
import { Typography, Paper, Button, Chip } from '@mui/material'; // import Chip
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CustomSnackbar from './CustomSnackbar'; // Import CustomSnackbar

function SingleStory({ story, userId, votes, status, setVoteSubmitted, votedStoryIds, setVotedStoryIds, isTie, votesRemaining, setVotesRemaining }) { // Changed from votedStoryId to votedStoryIds (array)
  const { content, _id } = story;
  const { currentUser } = useContext(AuthContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' }); // Add this state for the Snackbar

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleVote = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/vote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: currentUser.uid,
          storyId: _id
        })
      });

      const data = await response.json();

      if (response.ok) {
        if (votesRemaining > 1) {
          setVotesRemaining(votesRemaining - 1);  // Decrement remaining votes
          setVotedStoryIds([...votedStoryIds, _id]); // Add to the list of voted stories
          localStorage.setItem('votedStoryIds', JSON.stringify([...votedStoryIds, _id])); // Save to local storage
        } else {
          setVotesRemaining(votesRemaining - 1);  // Decrement remaining votes
          setVoteSubmitted(true);  // Only set this true when last vote is cast
          setVotedStoryIds([...votedStoryIds, _id]); // Add to the list of voted stories
          localStorage.setItem('votedStoryIds', JSON.stringify([...votedStoryIds, _id])); // Save to local storage
        }
      } else {
        // Handle 409 status code
        if (response.status === 409) {
          setSnackbar({ open: true, message: data.message, severity: 'info' });
        } else {
          setSnackbar({ open: true, message: data.message, severity: 'error' });
        }
      }
    } catch (error) {
      setSnackbar({ open: true, message: 'Error: Could not vote.', severity: 'error' });
    }
  };

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Paper style={{ padding: "16px", margin: "16px 0", backgroundColor: votedStoryIds.includes(_id) ? '#D8FBD8' : '#FFFFFF' }}> {/* Updated this line */}
      <div style={{ marginTop: "16px" }}>
        {isExpanded || content.length <= 200 ? content : `${content.substring(0, 200)}...`}
      </div>
      {status === 'results' && votes && <p>Votes: {votes}</p>}
      {status === 'results' && isTie && ( // Check for isTie
        <Typography variant="body2" color="textSecondary" style={{ fontSize: '0.7rem', color: '#B0B0B0' }}> {/* Adjust the font size */}
          (tiebreaker) Submitted: {new Date(story.createdAt).toLocaleString("en-US", { timeZone: "America/New_York" })} ET
        </Typography>
      )}
      {content.length > 200 && (
        <Button onClick={toggleExpand}>
          {isExpanded ? 'Show Less' : 'Show More'}
        </Button>
      )}
      {!votedStoryIds.includes(_id) && status !== 'results' && ( // Changed to check if ID is in the array
        <Button variant="contained" color="primary" onClick={handleVote}>
          Vote
        </Button>
      )}
      {votedStoryIds.includes(_id) && ( // Updated this line
        <Chip
          icon={<CheckBoxIcon />}
          label="You voted for this story!"
          color="primary"
          style={{ margin: '10px 0' }}
        />
      )}
      <CustomSnackbar 
        open={snackbar.open} 
        handleClose={closeSnackbar} 
        message={snackbar.message} 
        severity={snackbar.severity}
      />
    </Paper>
  );
}

export default SingleStory;
