import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Typography from '@mui/material/Typography';
import axios from 'axios';

const FeedbackUpvoteButton = ({ id, initialUpvotes, upvotedBy, userId }) => {
  const [upvotes, setUpvotes] = useState(initialUpvotes);
  const [upvoted, setUpvoted] = useState(upvotedBy.includes(userId));

  const handleUpvote = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/feedback/${id}/upvote`, {
        userId
      });
      setUpvotes(upvotes + 1);
      setUpvoted(true);
    } catch (error) {
      console.error('Error upvoting feedback:', error);
    }
  };

  return (
    <Button onClick={handleUpvote} size="small" variant="outlined" sx={{ color: upvoted ? "primary.main" : "grey.500", borderColor: "grey.500", p: '2px', height: 'auto', width: 'auto', minWidth: 'auto' }}>
      <Stack direction="column" spacing={-1} alignItems="center"> {/* Further reduce the spacing */}
        <ArrowDropUpIcon fontSize="large" />
        <Typography variant="body2">{upvotes}</Typography>
      </Stack>
    </Button>
  );
}

export default FeedbackUpvoteButton;
