// HomePage.js

import React from "react";
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from './logo-no-bg.png';
import usePageContent from './hooks/usePageContent';

function HomePage() {
  const { content, loading: contentLoading } = usePageContent('HomePage');

  if (contentLoading || !content) {
    return <p></p>;
  }

  const buttonLinks = ["/waitlist", "/login"];
  const callToAction = content.p.pop();

  return (
    <div className="page-container">
      <div className="content-container">
        <img src={logo} alt="Parentball Logo" className="logo" />
        {content.p.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
        <div className="button-container">
          {content.btn.map((label, index) => (
            <Button key={index} variant="contained" color={index === 0 ? "primary" : "secondary"} component={Link} to={buttonLinks[index]}>
              {label}
            </Button>
          ))}
        </div>
        <p dangerouslySetInnerHTML={{ __html: callToAction }} />
      </div>
      <footer>
        <p className="footer-links">
          <Link to="/terms-of-service">Terms of Service</Link> | <Link to="/privacy-policy">Privacy Policy</Link>
        </p>
      </footer>
    </div>
  );
}

export default HomePage;
