// WarmupQuestions.js
import React, { useState, useEffect } from 'react';
import { TextField, List, ListItem, ListItemText, Button, Box, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import axios from 'axios';
import { ListItemSecondaryAction, IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { Edit as EditIcon } from '@mui/icons-material';
import CustomSnackbar from './CustomSnackbar'; // replace './CustomSnackbar' with the correct path to your CustomSnackbar component
import CustomDialog from './CustomDialog'; // replace './CustomDialog' with the correct path to your CustomDialog component



const WarmupQuestions = () => {
  const [warmupQuestions, setWarmupQuestions] = useState([]);
  const [availableQuestions, setAvailableQuestions] = useState([]);
  const [editing, setEditing] = useState(null);  // Tracks the ID of the question being edited
  const [editedQuestion, setEditedQuestion] = useState({ options: ["", "", ""] });  // Tracks changes to the edited question
  const [showForm, setShowForm] = useState(false);
  const [newQuestion, setNewQuestion] = useState({
    questionText: "",
    options: ["", "", ""],
    associatedQuestionId: ""
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState(null);


  const handleEditChange = (event, questionId) => {
    if (event.target.name.startsWith('option')) {
      const options = [...editedQuestion.options];
      options[parseInt(event.target.name.slice(6)) - 1] = event.target.value;
      setEditedQuestion({ ...editedQuestion, options });
    } else {
      setEditedQuestion({
        ...editedQuestion,
        [event.target.name]: event.target.value
      });
    }
  };
  
  const handleEditSubmit = async (event, questionId) => {
    event.preventDefault();
    try {
      // Send all the current values of editedQuestion in the patch request
      await axios.patch(`${process.env.REACT_APP_API_URL}/warmup-question/${questionId}`, editedQuestion);
      setEditing(null);
      setEditedQuestion({ questionText: "", options: ["", "", ""] }); // Reset editedQuestion state
  
      setSnackbarMessage("Changes saved successfully.");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
  
      fetchWarmupQuestions();
    } catch (err) {
      console.error(err);
  
      setSnackbarMessage("Failed to save changes.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };
  
  const handleChange = (event) => {
    if (event.target.name.startsWith('option')) {
      const options = [...newQuestion.options];
      options[parseInt(event.target.name.slice(6)) - 1] = event.target.value;
      setNewQuestion({ ...newQuestion, options });
    } else {
      setNewQuestion({
        ...newQuestion,
        [event.target.name]: event.target.value
      });
    }
  };

  const addOption = () => {
    setNewQuestion(prevState => ({
      ...prevState,
      options: [...prevState.options, ""]
    }));
  };

  const addEditedOption = () => {
    setEditedQuestion(prevState => ({
      ...prevState,
      options: [...prevState.options, ""]
    }));
  };  

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Check if the user selected an associated question
    if (newQuestion.associatedQuestionId === "") {
      setSnackbarMessage("Please select an Associated Question.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return; // Prevent the rest of the function from running
    }
  
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/warmup-question`, newQuestion);
      setNewQuestion({
        questionText: "",
        options: ["", "", ""],
        associatedQuestionId: ""
      });
      setShowForm(false);
      fetchWarmupQuestions();
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = (id) => {
    setQuestionToDelete(id);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/warmup-question/${questionToDelete}`);
    
      if (response.status === 200) {
        setSnackbarMessage('Warmup question successfully deleted.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
    
        // Refetch the warmup questions
        fetchWarmupQuestions();
      } else {
        setSnackbarMessage('There was an error deleting the warmup question.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error(error);
      setSnackbarMessage('There was an error deleting the warmup question.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    
    setDeleteDialogOpen(false);
  };  

  const fetchWarmupQuestions = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/warmup-questions/current-season`);
      // Sort the warmup questions by their sequence before setting them to state
      const sortedQuestions = res.data.sort((a, b) => a.sequence - b.sequence);
      setWarmupQuestions(sortedQuestions);
  
      const questionIDs = new Set(res.data.map(q => q.associatedQuestionId));
      const available = await axios.get(`${process.env.REACT_APP_API_URL}/questions/current-season/no-warmup`);
       // New endpoint to get questions without warmup
      setAvailableQuestions(available.data.filter(q => !questionIDs.has(q._id)));
      
    } catch (err) {
      console.error(err);
    }
  };   

  useEffect(() => {
    fetchWarmupQuestions();
  }, []);

  return (
    <div>
      <h2>Current Season Warmup Questions</h2>

      <div>
        <Button variant="contained" color="secondary" onClick={() => setShowForm(!showForm)} startIcon={<AddIcon />}>New Warmup Question</Button>
      </div>

      {showForm && (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <TextField name="questionText" label="Question Text" fullWidth required onChange={handleChange} />
          {newQuestion.options.map((option, index) => (
            <TextField
              key={`option${index + 1}`}
              name={`option${index + 1}`}
              label={`Option ${index + 1}`}
              fullWidth required
              onChange={handleChange}
            />
          ))}
          <Button variant="contained" onClick={addOption} sx={{ mt: 2 }}>+ Option</Button>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="select-associated-question">Associated Question</InputLabel>
            <Select
                labelId="select-associated-question"
                id="associated-question"
                name="associatedQuestionId"
                value={newQuestion.associatedQuestionId}
                onChange={handleChange}
            >
                {availableQuestions.map((question) => (
                    <MenuItem key={question._id} value={question._id}>{`Q${question.sequence}: ${question.content}`}</MenuItem>
                ))}
            </Select>

          </FormControl>
          <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" type="submit">Save New Warmup Question</Button>
          </Grid>
        </Box>
      )}

        <List>
        {warmupQuestions.map((question) => (
            <ListItem key={question._id}>
            {editing === question._id ? (
                // If the question is being edited, display an editing form
                <Box component="form" onSubmit={(e) => handleEditSubmit(e, question._id)} sx={{ width: '100%' }}>
                <TextField name="questionText" label="Question Text" defaultValue={question.questionText} fullWidth onChange={handleEditChange} />
                {editedQuestion.options.map((option, index) => (
                    <TextField
                    key={`option${index + 1}`}
                    name={`option${index + 1}`}
                    label={`Option ${index + 1}`}
                    defaultValue={option}
                    fullWidth
                    onChange={handleEditChange}
                    />
                ))}
                <Button variant="contained" onClick={addEditedOption} sx={{ mt: 2 }}>+ Option</Button>
                <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                    <Button variant="contained" color="primary" type="submit">Save Changes</Button>
                    <Button variant="contained" color="secondary" onClick={() => setEditing(null)} sx={{ ml: 2 }}>Cancel</Button>
                </Grid>
                </Box>
            ) : (
                // If the question is not being edited, display the question text and options as usual
                <ListItemText
                primary={`WQ${question.sequence}: ${question.questionText}`}
                secondary={<div>{question.options.map((option, index) => <div key={index}>{option}</div>)}</div>}
                secondaryTypographyProps={{ component: 'div' }}
                />
            )}

            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(question._id)}>
                <DeleteIcon />
                </IconButton>
                <IconButton edge="end" aria-label="edit" onClick={() => {
                    setEditing(question._id);
                    setEditedQuestion({ questionText: question.questionText, options: question.options });
                    }}>
                    <EditIcon />
                </IconButton>
            </ListItemSecondaryAction>
            </ListItem>
        ))}
        </List>
        <CustomSnackbar 
          open={snackbarOpen} 
          handleClose={() => setSnackbarOpen(false)} 
          message={snackbarMessage} 
          severity={snackbarSeverity}
        />
        <CustomDialog
          open={deleteDialogOpen}
          handleClose={() => setDeleteDialogOpen(false)}
          title="Confirm Deletion"
          content="Are you sure you want to delete this warmup question?"
          confirmButtonLabel="Delete"
          handleConfirm={confirmDelete}
        />
      </div>
  );
};

export default WarmupQuestions;

