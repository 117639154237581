// Waitlist.js

import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField } from '@mui/material';
import CustomSnackbar from './CustomSnackbar';
import usePageContent from './hooks/usePageContent';

function Waitlist() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const { content, loading } = usePageContent('Waitlist');

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }  

  const handleSubmit = async (event) => {
    event.preventDefault();

    const email = event.target.elements.email.value;

    if (!validateEmail(email)) {
      setSnackbarMessage(content.alert.warning[0]);
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/waitlist`, { email });
      setSnackbarMessage(content.alert.success[0]);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error(error);
      setSnackbarMessage(content.alert.error[0]);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }

  if (loading) {
    return <p></p>;
  }

  return (
    <div className="page-container">
      <div className="content-container">
        {content.h1.map((header, index) => <h1 key={index}>{header}</h1>)}
        {content.p.map((paragraph, index) => <p key={index}>{paragraph}</p>)}
        <form onSubmit={handleSubmit} className="waitlist-form">
          <TextField id="email" label="Email" variant="outlined" fullWidth />
          <Button variant="contained" color="primary" type="submit">
            {content.btn[0]}
          </Button>
        </form>
      </div>
      <CustomSnackbar 
        open={snackbarOpen} 
        handleClose={() => setSnackbarOpen(false)} 
        message={snackbarMessage} 
        severity={snackbarSeverity}
      />
    </div>
  );
}

export default Waitlist;
