const piiNicknames = [
    "Niblet Overlord",
    "Cheeky Chimp",
    "Diminutive Dynamo",
    "Impish Imp",
    "Fledgling Phoenix",
    "Teeny Titan",
    "Sprog Sorcerer",
    "Duckling Daredevil",
    "Wee Warlock",
    "Elflet Emperor",
    "Nugget Ninja",
    "PeeWee Pirate",
    "Bantam Bandit",
    "Guppy Gladiator",
    "Sprite Spy",
    "Lilliputian Legionnaire",
    "Moppet Magician",
    "Poppet Prophet",
    "Runt Ranger",
    "Minnow Mercenary",
    "Bitty Buccaneer",
    "Puny Paragon",
    "Junior Jester",
    "Pipsqueak Paladin",
    "Toddler Tactician",
    "Cub Crusader",
    "Small Fry Savant",
    "Dwarf Demigod",
    "Wisp Warrior",
    "Munchkin Maestro",
    "Fryling Foreseer",
    "Hatchling Hero",
    "Petite Paladin",
    "Kiddy Kryptonian",
    "Elfkin Emissary",
    "Sprout Sprite",
    "Tike Templar",
    "Baby Bravado",
    "Knee-high Nomad",
    "Chitling Chieftain",
    "Squirt Squire",
    "Scamp Scout",
    "PeeWee Privateer",
    "Junior Juggernaut",
    "Ladling Lawgiver",
    "Poppet Phantom",
    "Bambino Barbarian",
    "Tyke Templar",
    "Mite Marshal",
    "Peanut Praetorian",
    "Zorgon",
    "Blipblorp",
    "Quiblix",
    "Zaltron",
    "Grebble",
    "Flibberjib",
    "Zeeble",
    "Qworp",
    "YipYop",
    "Glorgex",
    "ZibZap",
    "Ploobix",
    "Xenblatt",
    "BorpBorp",
    "Bliznorf",
    "Flarp",
    "Zibberflap",
    "Glabble",
    "Ploxx",
    "GorpGrop",
    "QuibbleQuox",
    "Blorgon",
    "Fleeb",
    "Greeble",
    "YibbleYop",
    "GlipGlop",
    "BlibberBlob",
    "Kvorg",
    "YibYob",
    "GlibbleGlop",
    "Xorgon",
    "Flubble",
    "Glibbix",
    "PlibberPlob",
    "BlipBlob",
    "GribGrob",
    "Xeeble",
    "Yorgle",
    "Blubble",
    "Kvibble",
    "ZorpZop",
    "Flibble",
    "Glorgle",
    "Quabble",
    "YobbleYip",
    "BlopBleep",
    "GleepGlop",
    "ZibbleZop",
    "GlopGleep",
];

export default piiNicknames;