// /hooks/useCategory.js

/*
This hook uses the useLocation hook from react-router-dom to get the current path, 
removes the leading slash, and returns it as the category. 

'overall' is the default category and used for the whole app
'homepage' is used for '/' (no relative path)
'email-{templateName in SendGrid}' is used for emails (ex/ 'email-waitlist')

It also uses the stringToColor function to generate a color based on the category string and return it.
*/

import { useLocation } from 'react-router-dom';

// Move stringToColor function outside the useCategory hook
export const stringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
}

const useCategory = (input) => {
    const location = useLocation();

    const category = input || location.pathname.slice(1); // use input if provided, otherwise remove leading slash

    return {
        category,
        color: stringToColor(category),
    };
}

export default useCategory;

