import React, { useState, useEffect } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, TextField, Typography } from '@mui/material';
import moment from 'moment-timezone';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CustomSnackbar from './CustomSnackbar';

// Increment/Decrement Buttons Component
const CustomNumberInput = ({ value, onChange, min }) => {
  const handleIncrement = () => {
    onChange({ target: { value: value + 1 } });
  };
  const handleDecrement = () => {
    if (value > min) {
      onChange({ target: { value: value - 1 } });
    }
  };

  return (
    <div>
      <IconButton onClick={handleDecrement} size="large">
        <RemoveIcon />
      </IconButton>
      <TextField
        type="number"
        inputProps={{ min, step: "1", readOnly: true }} // set input as read-only
        value={value}
        onKeyPress={handleKeyPress}
      />
      <IconButton onClick={handleIncrement} size="large">
        <AddIcon />
      </IconButton>
    </div>
  );
};

const handleKeyPress = (e) => {
  e.preventDefault();
};

const QuestionIntervals = () => {
  const [loading, setLoading] = useState(true); // New loading state
  const [questionsPerSeason, setQuestionsPerSeason] = useState(null); // Initialize to null
  const [intervals, setIntervals] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editedIntervals, setEditedIntervals] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [voting_date_delay, setVotingDateDelay] = useState(2);
  const [results_date_delay, setResultsDateDelay] = useState(4);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/settings`)
      .then(response => response.json())
      .then(data => {
        if (data.questionsPerSeason) {
          setQuestionsPerSeason(data.questionsPerSeason);
        }
        if (data.seasonStart) {
          const date = moment.tz(data.seasonStart, 'YYYY-MM-DD', 'America/New_York');
          setStartDate(date);
        }
        if (data.questionsPerSeason) {
          console.log('Setting questionsPerSeason to:', data.questionsPerSeason); // Debugging line
          setQuestionsPerSeason(data.questionsPerSeason);
        }
        if (data.questionIntervals) {
          // Convert array of objects to array of integers
          const loadedIntervals = data.questionIntervals.map(interval => parseInt(interval));
          setIntervals(loadedIntervals);
          setEditedIntervals([...loadedIntervals]);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Fetch error:', error);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;  // Or a loading spinner
  }   

  const padIntervals = (originalIntervals) => {
    const padded = [...originalIntervals];
    while (padded.length < questionsPerSeason) {
      const lastVal = padded[padded.length - 1] || 0;
      padded.push(lastVal + 1);
    }
    return padded.slice(0, questionsPerSeason);
  };
  
  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
    setEditedIntervals([...intervals]); // Reset the edited intervals to the initial state
  };

  const handleChange = (index) => (e) => {
    let newValue = parseInt(e.target.value);
    newValue = isNaN(newValue) ? '' : newValue;
  
    // get minimum value allowed
    const minVal = index === 0 ? 0 : editedIntervals[index - 1] + 1;
    
    // if newValue is less than minVal, assign it the value of minVal
    newValue = newValue < minVal ? minVal : newValue;
  
    const newIntervals = [...editedIntervals];
    newIntervals[index] = newValue;
    setEditedIntervals(newIntervals);
  
    // Adjust the values of subsequent questions
    for (let i = index + 1; i < newIntervals.length; i++) {
      if (newIntervals[i] <= newIntervals[i - 1]) {
        newIntervals[i] = newIntervals[i - 1] + 1;
      }
    }
    setEditedIntervals(newIntervals);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/questionIntervals`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ questionIntervals: editedIntervals }),
      });

      if (response.ok) {
        setSnackbarMessage('Question intervals successfully updated.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setIntervals([...editedIntervals]);
        setEditing(false);
      } else {
        setSnackbarMessage('There was an error updating the question intervals.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('There was an error updating the question intervals.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Updated the calculateDate function
  const calculateDate = (days, delayType) => {
    let additionalDays = 0;
    if (delayType === 'voting') {
      additionalDays = voting_date_delay;
    } else if (delayType === 'results') {
      additionalDays = results_date_delay;
    }

    if (startDate) {
      let date = moment.tz(startDate, 'America/New_York').add(days + additionalDays, 'days');
      return date.format('ddd M/D');
    }
  };

  // Make sure to return null while waiting for questionsPerSeason to be fetched
  // if (questionsPerSeason === null) {
  //   return null;
  // }  

  return (
    <TableContainer>
      <h2>Question Intervals</h2>
      <Typography variant="caption" display="block" gutterBottom>
        This controls when each question switches its status (staged, submission, voting, results).
        It also controls when SendGrid "Single Send" (bulk) emails are sent to all players.
        After you Edit and Save your changes, you must click the Set Schedule button below for the changes to take effect.
      </Typography>
      {editing ? (
        <Button variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
      ) : (
        <Button variant="contained" color="primary" onClick={handleEdit}>Edit</Button>
      )}
      {editing && (
        <Button variant="contained" color="secondary" onClick={handleCancel}>Cancel</Button>
      )}
      <Typography variant="caption" display="block">
        Parentball set to New York (ET) time zone. Questions switch status at ~3:00am. Emails are sent at ~5:00am.
      </Typography>
      <TableRow>
        <TableCell style={{ fontSize: '0.875rem' }}>
          Voting Date Delay:
          <button style={{ marginLeft: '10px' }} onClick={() => setVotingDateDelay(voting_date_delay - 1)}>-</button>
          <span style={{ margin: '0 10px' }}>{voting_date_delay}</span>
          <button onClick={() => setVotingDateDelay(voting_date_delay + 1)}>+</button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ fontSize: '0.875rem' }}>
          Results Date Delay:
          <button style={{ marginLeft: '10px' }} onClick={() => setResultsDateDelay(results_date_delay - 1)}>-</button>
          <span style={{ margin: '0 10px' }}>{results_date_delay}</span>
          <button onClick={() => setResultsDateDelay(results_date_delay + 1)}>+</button>
        </TableCell>
      </TableRow>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Q#</TableCell>
            <TableCell align="left">Days from Start Date</TableCell>
            <TableCell align="left">Sub. Date</TableCell>
            <TableCell align="left">Voting Date</TableCell>
            <TableCell align="left">Results Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {padIntervals(editing ? editedIntervals : intervals).map((interval, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell align="left">
                {editing ? (
                  <CustomNumberInput
                    value={editedIntervals[index]}
                    onChange={(newValue) => handleChange(index)(newValue)}
                    min={index === 0 ? 0 : editedIntervals[index - 1] + 1}
                  />                
                ) : (
                  interval
                )}
              </TableCell>
              <TableCell align="left">
                {calculateDate(interval)}
              </TableCell>
              <TableCell align="left">
                {calculateDate(interval, 'voting')} {/* Use voting_date_delay */}
              </TableCell>
              <TableCell align="left">
                {calculateDate(interval, 'results')} {/* Use results_date_delay */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <CustomSnackbar 
        open={snackbarOpen} 
        handleClose={() => { setSnackbarOpen(false); setEditing(false); }} 
        message={snackbarMessage} 
        severity={snackbarSeverity}
      />
    </TableContainer>
  );
};

export default QuestionIntervals;
