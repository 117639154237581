import React, { useEffect, useState } from 'react';
import StoryHeader from './StoryHeader';

const AllStories = () => {
  const [stories, setStories] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch all questions
    fetch(`${process.env.REACT_APP_API_URL}/questions/current-season`)
      .then(response => response.json())
      .then(data => {
        setQuestions(data);
        // Fetch all stories for each question
        Promise.all(data.map(question =>
          fetch(`${process.env.REACT_APP_API_URL}/vote/all-stories/${question._id}`)
            .then(response => response.json())
            .then(stories => stories.sort((a, b) => b.votes - a.votes || new Date(a.createdAt) - new Date(b.createdAt))) // Sorting logic here
        )).then(storiesByQuestion => {
          setStories(storiesByQuestion.flat());
          setLoading(false);
        });
      })
      .catch(err => console.error(err));
  }, []);

  // Group stories by questionId
  const storiesGroupedByQuestionId = stories.reduce((groups, story) => {
    if (!groups[story.questionId]) {
      groups[story.questionId] = [];
    }
    groups[story.questionId].push(story);
    return groups;
  }, {});

  // Display a loading message while data is being fetched
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>All Stories</h2>
      {questions.map(question => (
        <div key={question._id}>
          <h3>{`Q${question.sequence}: ${question.content}`}</h3>
          {storiesGroupedByQuestionId[question._id]?.map((story, index) => (
            <div key={story._id}>
              <StoryHeader story={story} index={index} status={question.status} username={story.username} showRanking={question.status === 'voting'} />
              <p>{story.content}</p> {/* Moved Content up */}
              <p>Votes: {story.votes}</p> {/* Moved Votes up */}
              <p>Submitted: {new Date(story.createdAt).toLocaleString("en-US", { timeZone: "America/New_York" })} ET</p> {/* Kept Submitted Date and Time */}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default AllStories;
