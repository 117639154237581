// CreateEmails.js

import React, { useState } from 'react';
import axios from 'axios';
import { Button, Typography, CircularProgress } from '@mui/material';
import CustomSnackbar from './CustomSnackbar';

function CreateDraftEmailsButton() {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [loading, setLoading] = useState(false); // state for loading indicator

    const handleClick = async () => {
        setLoading(true); // start loading
        try {
            let response = await axios.post(`${process.env.REACT_APP_API_URL}/createSeasonSingleSends`);
            if (response.status === 200) {
                response = await axios.post(`${process.env.REACT_APP_API_URL}/addSSIDsToDB`);
                if (response.status === 200) {
                    setSnackbarSeverity('success');
                    setSnackbarMessage('Draft emails successfully created and SSIDs added to DB.');
                } else {
                    throw new Error('Error while adding SSIDs to DB.');
                }
            } else {
                throw new Error('Error while creating draft emails.');
            }
        } catch (error) {
            setSnackbarSeverity('error');
            setSnackbarMessage(error.message);
        } finally {
            setSnackbarOpen(true);
            setLoading(false); // stop loading
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <div>
            <h2> Create Draft Emails</h2>
            <Button variant="contained" color="primary" onClick={handleClick} disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Bulk Create Draft Emails'}
            </Button>
            <Typography variant="body2" color="textSecondary">
                <br />
                This will create a draft Single Send email in SendGrid for all the ones that say "...SSID: NONE" above, and update the database with the correct SSIDs.<br />
                <br />
                It will not overwrite any current draft or scheduled emails.
            </Typography>
            <CustomSnackbar 
                open={snackbarOpen} 
                handleClose={handleClose} 
                message={snackbarMessage} 
                severity={snackbarSeverity} 
            />
        </div>
    );
}

export default CreateDraftEmailsButton;
