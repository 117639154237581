// usePageContent.js

import { useState, useEffect } from 'react';
import axios from 'axios';

const usePageContent = (page) => {
  const [content, setContent] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/content/${page}`)
      .then(response => {
        setContent(response.data.content);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, [page]);

  return { content, loading };
};

export default usePageContent;