import { initializeApp } from "firebase/app";
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, onAuthStateChanged } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics"; // commenting out as we are not using it currently
import { getFirestore, doc, updateDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);

// const analytics = getAnalytics(); // commenting out as we are not using it currently

const auth = getAuth(app);

const authStateChange = (userChange) => {
  return onAuthStateChanged(auth, userChange);
};

const updateUser = async (uid, data) => {
  const db = getFirestore();
  const userRef = doc(db, "users", uid);

  try {
    await updateDoc(userRef, data);
  } catch (error) {
    console.error("Error updating user: ", error);
  }
}

export { auth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, authStateChange, updateUser };

