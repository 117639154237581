import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import PrivateWrapper from './PrivateWrapper';
import AdminWrapper from './AdminWrapper';
import { useAuth } from './AuthContext';
import LoginPage from './LoginPage';
import HomePage from './HomePage';
import ConfirmEmail from './ConfirmEmail';
import StorySubmission from './StorySubmission';
import Story from './Story';
import EditStory from './EditStory';
import WarmupQuestion from './WarmupQuestion';
import Invite from './Invite';
import CurrentGame from './CurrentGame';
import Profile from './Profile';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import Standings from './Standings';
import MyActivity from './MyActivity';
import Admin from './Admin';
import ThankYou from './ThankYou';
import Nicknames from './Nicknames';
import ContentContainer from './ContentContainer';
import Waitlist from './Waitlist';
import WaitlistConfirmation from './WaitlistConfirmation';
import VoteSubmittedMessage from './VoteSubmittedMessage';
import NextQuestionMessage from './NextQuestionMessage';
import FeedbackButton from './FeedbackButton';
import axios from 'axios';

import './App.css';

//Added to include Material Drawer
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

// Define a RedirectComponent
function RedirectComponent() {
  const navigate = useNavigate();
  
  useEffect(() => {
    navigate('/login');
  }, [navigate]);

  return null;
}

// Define the AppLayout component.
function AppLayout() {
  const { currentUser, role } = useAuth(); // Fetch currentUser and role from the AuthContext
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);  // Add a loading state
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      console.log('Current user:', currentUser);  // Log the currentUser object
      // Fetch the user's data from the backend using axios
      axios.get(`${process.env.REACT_APP_API_URL}/users/${currentUser.uid}`)
        .then(response => {
          const data = response.data;
          console.log('User data:', data);  // Log the user data
          // If the user is a first time user, open the drawer
          if (data.firstTimeUser) {
            setDrawerOpen(true);
          }
        })
        .catch(error => console.error('Error fetching user data:', error))
        .finally(() => {
          // When all operations are complete, set loading to false
          setLoading(false);
        });
    } else {
      // If there's no currentUser, set loading to false
      setLoading(false);
    }
  }, [currentUser]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleMenuClick = (path) => {
    // Make a request to the backend to set firstTimeUser to false using axios
    axios.put(`${process.env.REACT_APP_API_URL}/first-time-user`, {
      uid: currentUser.uid,
      firstTimeUser: false
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .catch(error => console.error('Error updating first-time user status:', error));
    
    navigate(path);
    setDrawerOpen(false);
  };

  return (
    <>
    {loading ? (
      // Render a loading spinner or some other loading indicator
      <div></div>
    ) : (
      <>
        {currentUser && (
          <>
            <nav>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon fontSize="large" />
            </IconButton>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
              <IconButton onClick={toggleDrawer(false)}>
                <CloseIcon fontSize="large" />
              </IconButton>
              <List>
                {['Submit Story', 'Current Game', 'Standings', 'My Activity', 'Profile'].map((text, index) => (
                  <ListItem button key={text} onClick={() => handleMenuClick('/' + text.toLowerCase().replace(' ', '-'))}>
                    <ListItemText primary={text} />
                  </ListItem>
                ))}
                {role === 'superadmin' && (
                  <ListItem button key="Admin" onClick={() => handleMenuClick('/admin')}>
                    <ListItemText primary="Admin" />
                  </ListItem>
                )}
              </List>
            </Drawer>
            </nav>
            <FeedbackButton />
          </>
        )}
        {/* UPDATE src/PageContentRoutes.js any time you add a route here*/}
        <Routes>
        <Route path="/login" element={<ContentContainer><LoginPage /></ContentContainer>} />
        <Route path="/confirm-email" element={<ContentContainer><PrivateWrapper><ConfirmEmail /></PrivateWrapper></ContentContainer>} />
        <Route path="/" element={<ContentContainer><HomePage /></ContentContainer>} />
        <Route path="/submit-story" element={<ContentContainer><PrivateWrapper><StorySubmission /></PrivateWrapper></ContentContainer>} />
        <Route path="/story/:id" element={<ContentContainer><PrivateWrapper><Story /></PrivateWrapper></ContentContainer>} />
        <Route path="/edit-story/:id" element={<ContentContainer><PrivateWrapper><EditStory /></PrivateWrapper></ContentContainer>} />
        <Route path="/warmup-question" element={<ContentContainer><PrivateWrapper><WarmupQuestion /></PrivateWrapper></ContentContainer>} />
        <Route path="/invite" element={<ContentContainer><PrivateWrapper><Invite /></PrivateWrapper></ContentContainer>} />
        <Route path="/current-game" element={<ContentContainer><PrivateWrapper><CurrentGame /></PrivateWrapper></ContentContainer>} />
        <Route path="/profile" element={<ContentContainer><PrivateWrapper><Profile /></PrivateWrapper></ContentContainer>} />
        <Route path="/terms-of-service" element={<ContentContainer><TermsOfService /></ContentContainer>} />
        <Route path="/privacy-policy" element={<ContentContainer><PrivacyPolicy /></ContentContainer>} />
        <Route path="/standings" element={<ContentContainer><PrivateWrapper><Standings /></PrivateWrapper></ContentContainer>} />
        <Route path="/my-activity" element={<ContentContainer><PrivateWrapper><MyActivity /></PrivateWrapper></ContentContainer>} />
        <Route path="/signup/*" element={<ContentContainer><RedirectComponent /></ContentContainer>} />
        <Route path="/admin" element={<ContentContainer><AdminWrapper><Admin /></AdminWrapper></ContentContainer>} />
        <Route path="/thank-you/:id" element={<ContentContainer><ThankYou /></ContentContainer>} />
        <Route path="/nicknames" element={<ContentContainer><Nicknames /></ContentContainer>} />
        <Route path="/waitlist" element={<ContentContainer><Waitlist /></ContentContainer>} />
        <Route path="/waitlist-confirmation" element={<ContentContainer><WaitlistConfirmation /></ContentContainer>} />
        <Route path="/warmup-question" element={<ContentContainer><PrivateWrapper><WarmupQuestion /></PrivateWrapper></ContentContainer>} />
        <Route path="/vote-submitted-message" element={<ContentContainer><PrivateWrapper><VoteSubmittedMessage /></PrivateWrapper></ContentContainer>} />
        <Route path="/next-question-message" element={<ContentContainer><PrivateWrapper><NextQuestionMessage /></PrivateWrapper></ContentContainer>} />
        </Routes>
      </>
    )}
  </>
);}

// Define the App component.
function App() {
  return (
    <Router>
      <AppLayout />
    </Router>
  );
}

export default App;
