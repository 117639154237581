// Nicknames.js
import React, { useState } from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import ClipboardCopyIcon from '@mui/icons-material/FileCopy';
import piiNicknames from './PiiNicknames';
import CustomSnackbar from './CustomSnackbar';
import usePageContent from './hooks/usePageContent';

const Nicknames = () => {
  const { content, loading } = usePageContent('Nicknames');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  if (loading || !content) {
    return <p></p>;
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    // First, close the Snackbar
    setSnackbar({ open: false, message: '', severity: 'success' });
    // Use a timeout to ensure the Snackbar has time to close before reopening it
    setTimeout(() => setSnackbar({ open: true, message: `${text} copied`, severity: 'success' }), 100);
  };

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container maxWidth="md">
      <h1>{content.h1[0]}</h1>
      <Typography variant="body1" gutterBottom>
        {content.p[0]}
      </Typography>
      <Box 
        display="flex" 
        flexWrap="wrap" 
        justifyContent="center" 
        gap={2} 
        marginTop={4}
      >
        {piiNicknames.map((nickname, index) => (
          <Button 
            key={index} 
            variant="outlined" 
            onClick={() => copyToClipboard(nickname)} 
            endIcon={<ClipboardCopyIcon />}
          >
            {nickname}
          </Button>
        ))}
      </Box>
      <CustomSnackbar 
        open={snackbar.open} 
        handleClose={closeSnackbar} 
        message={snackbar.message} 
        severity={snackbar.severity}
      />
    </Container>
  );
}

export default Nicknames;
