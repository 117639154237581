// PageContentRoutes.js

// UPDATE ANY TIME YOU ADD A ROUTE TO APP.JS

export const routesList = [
  { path: '/login', componentName: 'LoginPage'},
  { path: '/confirm-email', componentName: 'ConfirmEmail'},
  { path: '/', componentName: 'HomePage'},
  { path: '/submit-story', componentName: 'StorySubmission'},
  { path: '/story/:id', componentName: 'Story'},
  { path: '/edit-story/:id', componentName: 'EditStory'},
  { path: '/warmup-question', componentName: 'WarmupQuestion'},
  { path: '/invite', componentName: 'Invite'},
  { path: '/current-game', componentName: 'CurrentGame'},
  { path: '/profile', componentName: 'Profile'},
  { path: '/terms-of-service', componentName: 'TermsOfService'},
  { path: '/privacy-policy', componentName: 'PrivacyPolicy'},
  { path: '/standings', componentName: 'Standings'},
  { path: '/my-activity', componentName: 'MyActivity'},
  { path: '/admin', componentName: 'Admin'},
  { path: '/thank-you/:id', componentName: 'ThankYou'},
  { path: '/nicknames', componentName: 'Nicknames'},
  { path: '/waitlist', componentName: 'Waitlist'},
  { path: '/waitlist-confirmation', componentName: 'WaitlistConfirmation'},
  { path: '/vote-submitted-message', componentName: 'VoteSubmittedMessage' },
  { path: '/next-question-message', componentName: 'NextQuestionMessage' },
  // Add more routes as needed...
];
