// TermsOfService.js
import React from 'react';
import usePageContent from './hooks/usePageContent';

function TermsOfService() {
  const { content, loading } = usePageContent('termsofservice');
  
  if (loading) {
    return <div></div>
  }
  
  return (
    // TO DO: REPLACE [Your Jurisdiction], [Arbitration Provider] and [Contact Email]
    <div dangerouslySetInnerHTML={{ __html: content.div }} />
  );
}

export default TermsOfService;