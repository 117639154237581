const adjectives = ["Fast", 
"Happy", 
"Jumping", 
"Running", 
"Crazy", 
"Brave", 
"Bold", 
"Sunny", 
"Caring", 
"Funny", 
"Charming", 
"Cheerful", 
"Daring", 
"Energetic", 
"Friendly", 
"Gentle", 
"Hilarious", 
"Inventive", 
"Jolly", 
"Kind", 
"Lively", 
"Merry", 
"Nurturing", 
"Optimistic", 
"Playful", 
"Quick", 
"Radiant", 
"Spirited", 
"Trustworthy", 
"Upbeat", 
"Vivacious", 
"Witty", 
"Excited", 
"Yummy", 
"Zany", 
"Adventurous", 
"Blissful", 
"Clever", 
"Delightful", 
"Enthusiastic", 
"Fabulous", 
"Generous", 
"Hopeful", 
"Imaginative", 
"Joyful", 
"Keen", 
"Loving", 
"Magical", 
"Nifty", 
"Outstanding", 
"Passionate", 
"Reliable", 
"Spectacular", 
"Talented", 
"Unique", 
"Versatile", 
"Warm", 
"Youthful", 
"Zesty", 
"Amazing", 
"Blazing", 
"Cozy", 
"Dreamy", 
"Elegant", 
"Fierce", 
"Graceful", 
"Harmonious", 
"Incredible", 
"Jazzy", 
"Knightly", 
"Loyal", 
"Mighty", 
"Noble", 
"Original", 
"Peaceful", 
"Refreshing", 
"Sparkling", 
"Terrific", 
"Unstoppable", 
"Victorious", 
"Wonderful", 
"Xenial", 
"Yearning", 
"Zealous", 
"Animated", 
"Breezy", 
"Compassionate", 
"Dynamic", 
"Effervescent", 
"Flamboyant", 
"Glowing", 
"Heartwarming", 
"Inspiring", 
"Jubilant", 
"Knowledgeable", 
"Luminous", 
"Majestic", 
"Nimble", 
"Persistent", 
"Quirky", 
"Resilient", 
"Stunning", 
"Thrilling", 
"Unforgettable", 
"Vibrant", 
"Wholesome", 
"Xtraordinary", 
"Zeppelin", 
"Ambitious", 
"Brilliant", 
"Colorful", 
"Devoted", 
"Exuberant", 
"Feisty", 
"Gregarious", 
"Honest", 
"Ingenious", 
"Jaunty", 
"Kind-hearted", 
"Lucky", 
"Mindful", 
"Nostalgic", 
"Open-minded", 
"Persevering", 
"Quaint", 
"Resourceful", 
"Sincere", 
"Tranquil", 
"Unyielding", 
"Valiant", 
"Wise", 
"Xuberant", 
"Young-at-heart", 
"Agile", 
"Bright", 
"Calm", 
"Dazzling", 
"Expressive", 
"Fiery", 
"Gracious", 
"Intriguing", 
"Joyous", 
"Laid-back", 
"Mirthful", 
"Outgoing", 
"Pleasant", 
"Quixotic", 
"Resolute", 
"Tireless", 
"Understanding", 
"Xciting", 
"Yielding", 
"Zippy", 
"Adorable", 
"Bountiful", 
"Courageous", 
"Determined", 
"Epic", 
"Fascinating", 
"Heroic", 
"Independent", 
"Jovial", 
"Kindly", 
"Liberated", 
"Motivated", 
"Observant", 
"Patient", 
"Quick-witted", 
"Respectful", 
"Sweet", 
"Thoughtful", 
"Unassuming", 
"Valuable", 
"Xenodochial", 
"Zappy", 
"Affectionate", 
"Content", 
"Diligent", 
"Eager", 
"Fearless", 
"Genuine", 
"Humorous", 
"Intelligent", 
"Just", 
"Kindred", 
"Light-hearted", 
"Marvelous", 
"Neat", 
"Outspoken", 
"Practical", 
"Responsible", 
"Sensitive", 
"Tender", 
"Virtuous", 
"Wacky", 
"Young", 
"Boisterous", 
"Curious", 
"Dedicated", 
"Efficient", 
"Faithful", 
"Gallant", 
"Honorable", 
"Impressive", 
"Meticulous", 
"Nice", 
"Organized", 
"Quiet", 
"Steady", 
"Unflappable", 
"Vigorous", 
"Zestful", 
"Amicable", 
"Brisk", 
"Capable", 
"Fantastic", 
"Hardworking", 
"Kinetic", 
"Logical", 
"Willing", 
"Xtra", 
"Affable", 
"Blithesome", 
"Elated", 
"Gleeful", 
"Hardy", 
"Intuitive", 
"Jocular", 
"Kooky", 
"Opulent", 
"Prudent", 
"Tidy", 
"Unruffled", 
"Whimsical", 
"Cool", 
"Exquisite", 
"Funky", 
"Grateful", 
"Inspirational", 
"Quality", 
"Tactful", 
"Admirable", 
"Candid", 
"Fruitful", 
"Impartial", 
"Lovely", 
"Modest", 
"Open-hearted", 
"Quintessential", 
"Robust", 
"Thrifty", 
"Dutiful", 
"Eccentric", 
"Innovative", 
"Rambunctious", 
"Tenacious", 
"Unbeatable", 
"Bubbly", 
"Driven", 
"Easygoing", 
"Confident", 
"Exciting", 
"Benevolent", 
"Inquisitive", 
"Steadfast", 
"Artistic", 
"Buoyant", 
"Charismatic", 
"Dependable", 
"Empathetic", 
"Flawless", 
"Idealistic", 
"Kempt", 
"Laudable", 
"Nourishing", 
"Opalescent", 
"Praiseworthy", 
"Sage", 
"Timeless", 
"Uncompromising", 
"Visionary", 
"Altruistic", 
"Committed", 
"Dexterous", 
"Effusive", 
"Free-spirited", 
"Invaluable", 
"Kaleidoscopic", 
"Meritorious", 
"Nonchalant", 
"Omniscient", 
"Precise", 
"Resplendent", 
"Stoic", 
"Unpretentious", 
"Warm-hearted", 
"Zealful", 
"Ebullient", 
"Intrepid", 
"Munificent", 
"Nonpareil", 
"Philanthropic", 
"Unimpeachable", 
"Welcoming", 
"Xenogeneic", 
"Assertive", 
"Beneficent", 
"Captivating", 
"Debonair", 
"Exemplary", 
"Fervent", 
"Magnanimous", 
"Natty", 
"Prodigious", 
"Quiescent", 
"Reverent", 
"Savvy", 
"Fair-minded", 
"Selfless", 
"Beneficial", 
"Cordial", 
"Humble", 
"Vigilant", 
"Courteous", 
"Fair", 
"Appreciative", 
"Chivalrous", 
"Flourishing", 
"Humane", 
"Innocent", 
"Kingly", 
"Liberal", 
"Righteous", 
"Serenity", 
"Uplifting", 
"Worthy", 
"Assiduous", 
"Cautious", 
"Gratifying", 
"Hospitable", 
"Invigorating", 
"Nonpartisan", 
"Objective", 
"Unswerving", 
"Venerable", 
"Affirmative", 
"Cherished", ];

const nouns = ["Apple", 
"Bear", 
"Cheetah", 
"Dolphin", 
"Eagle", 
"Fox", 
"Giraffe", 
"Hedgehog", 
"Iguana", 
"Jaguar", 
"Koala", 
"Lion", 
"Moose", 
"Nightingale", 
"Octopus", 
"Penguin", 
"Quail", 
"Raccoon", 
"Snail", 
"Tiger", 
"Unicorn", 
"Vulture", 
"Walrus", 
"Xenops", 
"Yak", 
"Zebra", 
"Actor", 
"Baker", 
"Carpenter", 
"Dancer", 
"Engineer", 
"Firefighter", 
"Gardener", 
"Historian", 
"Illustrator", 
"Juggler", 
"Kite", 
"Lantern", 
"Moon", 
"Nurse", 
"Optometrist", 
"Painter", 
"Queen", 
"Ranger", 
"Star", 
"Teacher", 
"Umbrella", 
"Violin", 
"Whale", 
"Xylophone", 
"Yacht", 
"Zucchini", 
"Alien", 
"Banana", 
"Cactus", 
"Daisy", 
"Elephant", 
"Flamingo", 
"Grasshopper", 
"Hamster", 
"Iceberg", 
"Jackal", 
"Kangaroo", 
"Lizard", 
"Mango", 
"Narwhal", 
"Orangutan", 
"Peacock", 
"Quokka", 
"Rabbit", 
"Starfish", 
"Toucan", 
"Uakari", 
"Vampire", 
"Walnut", 
"Xerus", 
"Yam", 
"Zephyr", 
"Ant", 
"Bulldog", 
"Caterpillar", 
"Duck", 
"Echidna", 
"Frog", 
"Goose", 
"Hawk", 
"Insect", 
"Jay", 
"Kitten", 
"Leopard", 
"Mongoose", 
"Nest", 
"Owl", 
"Panda", 
"Rooster", 
"Spider", 
"Tortoise", 
"Urchin", 
"Vixen", 
"Wolf", 
"Xrayfish", 
"Yabby", 
"Zebu", 
"Armadillo", 
"Bluejay", 
"Crow", 
"Dragonfly", 
"Ferret", 
"Gazelle", 
"Horse", 
"Ibis", 
"Jackrabbit", 
"Kiwi", 
"Lemur", 
"Meerkat", 
"Ocelot", 
"Pelican", 
"Quetzal", 
"Rhinoceros", 
"Skunk", 
"Tadpole", 
"Umbrellabird", 
"Vole", 
"Wombat", 
"Xenopus", 
"Yellowjacket", 
"Zebrafish", 
"Agama", 
"Beetle", 
"Coral", 
"Donkey", 
"Finch", 
"Grouse", 
"Hummingbird", 
"Impala", 
"Jellyfish", 
"Lynx", 
"Moth", 
"Newt", 
"Ostrich", 
"Parrot", 
"Squid", 
"Turtle", 
"Urial", 
"Viper", 
"Weasel", 
"Zorro", 
"Angel", 
"Butterfly", 
"Cloud", 
"Dandelion", 
"Echo", 
"Falcon", 
"Ghost", 
"Harmony", 
"Inventor", 
"Joy", 
"Knight", 
"Mystery", 
"Ninja", 
"Orchid", 
"Poet", 
"Quartz", 
"Rainbow", 
"Sorcerer", 
"Truth", 
"Universe", 
"Victory", 
"Whisper", 
"Xenon", 
"Yearning", 
"Zenith", 
"Atom", 
"Blizzard", 
"Canyon", 
"Dewdrop", 
"Eclipse", 
"Firefly", 
"Galaxy", 
"Horizon", 
"Icicle", 
"Journey", 
"Kaleidoscope", 
"Lullaby", 
"Meadow", 
"Nova", 
"Oasis", 
"Phoenix", 
"Quicksilver", 
"River", 
"Solstice", 
"Twilight", 
"Vortex", 
"Wind", 
"Xenolith", 
"Yonder", 
"Zen", 
"Adventure", 
"Blossom", 
"Chime", 
"Dream", 
"Ember", 
"Flame", 
"Glow", 
"Harvest", 
"Infinite", 
"Jewel", 
"Kingdom", 
"Legend", 
"Mirage", 
"Nightfall", 
"Omen", 
"Paradise", 
"Quasar", 
"Revelation", 
"Silhouette", 
"Thistle", 
"Utopia", 
"Voyage", 
"Whirlwind", 
"Xanadu", 
"Yesterday", 
"Abyss", 
"Breeze", 
"Charm", 
"Dusk", 
"Evergreen", 
"Frost", 
"Glisten", 
"Illusion", 
"Jubilee", 
"Kismet", 
"Hippo", 
"Monkey", 
"Dad", 
"Supermom", 
"Shark", 
"Buffalo", 
"Chameleon", 
"Seal", 
"Aardvark", 
"Badger", 
"Camel", 
"Eel", 
"Hyena", 
"Llama", 
"Mantis", 
"Sloth", 
"Xiphosura", 
"Bobcat", 
"Coyote", 
"Dingo", 
"Emu", 
"Gorilla", 
"Kudu", 
"Porcupine", 
"Scorpion", 
"Tarantula", 
"Vicuna", 
"Xantus", 
"Yucca", 
"Zander", 
"Alpaca", 
"Cricket", 
"Dove", 
"Gnu", 
"Inchworm", 
"Ladybug", 
"Nuthatch", 
"Oryx", 
"Robin", 
"Swan", 
"Weaverbird", 
"X-Ray-Tetra", 
"Zooplankton", 
"Axolotl", 
"Beaver", 
"Chinchilla", 
"Dalmatian", 
"Eland", 
"Fennec", 
"Guppy", 
"Heron", 
"Jerboa", 
"Koi", 
"Lapwing", 
"Marmoset", 
"Numbat", 
"Opossum", 
"Platypus", 
"Salamander", 
"Tamarin", 
"Vervet", 
"Wallaby", 
"Yaffle", 
"Boar", 
"Cardinal", 
"Dunnock", 
"Elk", 
"Gull", 
"Hyrax", 
"Ibex", 
"Kestrel", 
"Lemming", 
"Minnow", 
"Otter", 
"Plover", 
"Roe", 
"Shrew", 
"Tern", 
"Warbler", 
"Zorilla", 
"Bison", 
"Cormorant", 
"Dugong", 
"Egret", 
"Gerbil", 
"Hartebeest", 
"Iguanodon", 
"Kakapo", 
"Mink", 
"Osprey", 
"Puffin", 
"Siskin", 
"Xeme", 
"Yeti", 
"Barbet", 
"Curlew", 
"Dormouse", 
"Goshawk", 
"Hoopoe", 
"Ichneumon", 
"Lorikeet", 
"Marten", 
"Quagga", 
"Rhea", 
"Sable", 
"Tawny", 
"Umbrette", 
"Vireo", 
"Wapiti", 
"Xenarthra", 
"Bobwhite", 
"Cuckoo", 
"Eider", 
"Fulmar", 
"Guillemot", 
"Jackdaw", 
"Lark", 
"Merlin", 
"Oystercatcher", 
"Pipit", 
"Redstart", 
"Skylark", 
"Teal", 
"Upland", 
"Wagtail", 
"Yuhina", 
"Zebrafinch", 
"Bunting", 
"Chat", 
"Dipper", 
"Grosbeak", 
"Harrier", 
"Iora", 
"Junco", 
"Kittiwake", 
"Martin", 
"Ouzel", 
"Rail", 
"Sanderling", 
"Tanager", 
"Yellowthroat", 
"Zosterops", 
"Blackbird", 
"Crane", 
"Grebe", 
"Jacana", 
"Kingfisher", 
"Loon", 
"Moorhen", 
"Nightjar", 
"Oriole", 
"Raven", 
"Snipe", 
"Woodcock", 
"Yellowhammer", 
"Cicada", 
"Earwig", 
"Hornet", 
"Jewelbug", 
"Katydids", 
"Ladybird", 
"Mayfly", 
"Nymph", 
"Orbweaver", 
"Pseudoscorpion", 
"Queenbee", 
"Rovebeetle", 
"Silverfish", 
"Termite", 
"Ulysses", 
"Vinegaroon", 
"Weevil", 
"Xysticus", 
"Zorapteran", 
"Bee", 
"Damselfly", 
"Fruitfly", 
"Honeybee", 
"Jewelbeetle", 
"Katydid", 
"Nematode", 
"Orthoptera", 
"Prayingmantis", 
"Queenant", 
"Thrips", 
"Uloboridae", 
"Vinegarfly", 
"Wasp", 
"Xerces", 
"Zygaena", ];

export function generateUsernames() {
  let usernames = [];
  for(let i = 0; i < 3; i++) {
    let adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    let noun = nouns[Math.floor(Math.random() * nouns.length)];
    usernames.push(adjective + " " + noun);
  }
  return usernames;
}