// Admin.js

import React, { useState, useEffect } from 'react';
import SetSeason from './SetSeason';
import QuestionsPerSeason from './QuestionsPerSeason';
import VotesPerStory from './VotesPerStory';
import StartDate from './StartDate';
import QuestionIntervals from './QuestionIntervals';
import SetScheduleButton from './SetScheduleButton';
import Questions from './Questions';
import CheckUserRole from './ChangeUserRole';
import WarmupQuestions from './WarmupQuestions';
import SetPunnyTitle from './SetPunnyTitle';
import AllStories from './AllStories';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';
import CreateDraftEmailsButton from './CreateEmails';
import PageContent from './PageContent';
import UpdateNullUsernames from './UpdateNullUsernames';
import Players from './Players';
import PlayerEngagement from './PlayerEngagement';
import EmailEngagement from './EmailEngagement';
// import OverallEngagement from './OverallEngagement'; // COMMENT OUT UNTIL FINISHED

const Admin = () => {
  const [season, setSeason] = useState(null);

  const [expanded, setExpanded] = useState(() => {
    const saved = localStorage.getItem('expandedAccordion');
    return saved ? JSON.parse(saved) : 'panel1';
  });

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    localStorage.setItem('expandedAccordion', JSON.stringify(newExpanded ? panel : false));
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/settings/season`) // Replace this with your server route
      .then(response => response.json())
      .then(data => setSeason(data.currentSeason));
  }, []);

  return (
    <Box sx={{ width: '100%', padding: 0 }}>
      <h1>Admin Page</h1>

      {/* Accordion for Global App Configuration like Page Content*/}
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} sx={{ width: '100%', padding: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>App</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ flexDirection: 'column' }}>
          <PageContent />
        </AccordionDetails>
      </Accordion>
      
      {/* Accordion for Seasons Configuration */}
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ width: '100%', padding: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Seasons</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ flexDirection: 'column' }}>
          {season !== null && <h2>Current Season: {season}</h2>}
          <SetSeason />
          <QuestionsPerSeason />
          <VotesPerStory />  {/* <-- Add this line */}
          <Questions />
          <CreateDraftEmailsButton/>
          <WarmupQuestions />
          <StartDate />
          <QuestionIntervals />
          <SetScheduleButton />
        </AccordionDetails>
      </Accordion>

      {/* Accordion for Players */}
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{ width: '100%', padding: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Players</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ flexDirection: 'column' }}>
          <Players />
          <CheckUserRole />
          <UpdateNullUsernames /> {/* new component */}
        </AccordionDetails>
      </Accordion>

      {/* Accordion for Stories */}
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{ width: '100%', padding: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Stories</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ flexDirection: 'column' }}>
          <SetPunnyTitle />
          <AllStories /> {/* new component */}
        </AccordionDetails>
      </Accordion>

      {/* Accordion for Analytics (or Engagement) */}
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} sx={{ width: '100%', padding: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>Analytics</Typography> {/* Or "Engagement" */}
        </AccordionSummary>
        <AccordionDetails sx={{ flexDirection: 'column' }}>
          <EmailEngagement />
          <PlayerEngagement />
          {/* <OverallEngagement />  COMMENT OUT UNTIL FINISHED*/}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Admin;
