// FeedbackSearch.js

import React, { useState } from 'react';
import { TextField } from '@mui/material';

const FeedbackSearch = ({ feedbacks, setFilteredFeedbacks }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value === '') {
      setFilteredFeedbacks(feedbacks);
    } else {
      const filtered = feedbacks.filter(({ category, feedbacks }) => {
        return feedbacks.some(feedback => 
          feedback.title.toLowerCase().includes(event.target.value.toLowerCase()) ||
          feedback.description.toLowerCase().includes(event.target.value.toLowerCase()) ||
          category.toLowerCase().includes(event.target.value.toLowerCase())
        );
      });
      setFilteredFeedbacks(filtered);
    }
  };

  return (
    <TextField
      value={searchTerm}
      onChange={handleSearch}
      label="Search for existing feedback..."
      variant="outlined"
      fullWidth
    />
  );
};

export default FeedbackSearch;