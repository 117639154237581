import React from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

function NewFeedbackButton({ onClick }) {
  return (
    <Button variant="contained" color="secondary" startIcon={<AddIcon />} onClick={onClick}>
      New Feedback
    </Button>

  );
}

export default NewFeedbackButton;
