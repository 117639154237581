import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import theme from './theme'; // Corrected import
import usePageContent from './hooks/usePageContent';

// Extract colors from theme
const primaryColor = theme.palette.primary.main;
const secondaryColor = theme.palette.secondary.main;

// Function to lighten or darken color
const adjustColor = (color, amount) => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

// Prepare colors array
const confettiColors = [
    primaryColor, 
    secondaryColor,
    adjustColor(primaryColor, -20),
    adjustColor(primaryColor, 20),
    adjustColor(secondaryColor, -20),
    adjustColor(secondaryColor, 20),
];

// Define the ThankYou component
const ThankYou = () => {
    let navigate = useNavigate();

    // Get the storyId from the URL parameters
    const { id } = useParams();

    // Get the window size for confetti
    const { width, height } = useWindowSize();

    // Get the page content
    const { content, loading } = usePageContent('thankYou');

    if (loading) {
        return null;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                textAlign: 'center',
                bgcolor: 'background.default',
                px: 2,
            }}
        >
            <Confetti 
                width={width} 
                height={height} 
                colors={confettiColors}
            />
            <h1>
                {content.h1[0] || 'Thanks for your submission!'}
            </h1>
            <h2>
                {content.h2[0] || '🍼 Parenting is hard, but you\'re doing great! 🏀'}
            </h2>
            <Typography variant="body1" component="p" gutterBottom>
                {content.p[0] || 'Stay tuned to vote for your favorite story tomorrow.'}
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(`/story/${id}`)}
                sx={{mt: 4}}
            >
                {content.btn[0] || 'View Story'}
            </Button>
        </Box>
    );
};

export default ThankYou;
