// /src/NewFeedback.js

import React, { useState, useContext } from 'react';
import { Modal, Box, TextField, Button, Chip, useTheme } from '@mui/material';  // <-- Import Chip
import CloseButton from './CloseButton';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import useCategory, { stringToColor } from './hooks/useCategory';  // <-- Import stringToColor

const NewFeedback = ({ open, handleClose, handleNewFeedback }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const { category } = useCategory();  // <-- category obtained from useCategory

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/feedback`, {
        title: title,
        description: description,
        userId: currentUser.uid,
        category: category,
        upvotes: 1,
        upvotedBy: [currentUser.uid],
      });
      if (response.status === 201) {
        // Clear the form
        setTitle('');
        setDescription('');
        // Close the modal
        handleClose();
        // Call the callback function from the parent component
        handleNewFeedback('Feedback submitted successfully!', 'success');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      // Call the callback function from the parent component
      handleNewFeedback('Error submitting feedback. Please try again.', 'error');
    }
  };

  const color = stringToColor(category);  // <-- Get the color for the category

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="new-feedback-modal-title"
      aria-describedby="new-feedback-modal-description"
      fullWidth
      maxWidth="md"
    >
      <Box sx={{ 
        height: '100vh',
        overflowY: 'scroll',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h1>
            New Feedback{' '}
            <Chip 
              label={category} 
              size="small" 
              variant="outlined"
              sx={{
                color: 'white',
                backgroundColor: color,  // Use the color from stringToColor
                borderColor: color,  // Use the color from stringToColor
                borderRadius: 0,
                fontSize: '0.8rem'
              }}
            />
          </h1>
          <CloseButton onClick={handleClose} />
        </Box>
        <TextField
          label="Title"
          value={title}
          onChange={e => setTitle(e.target.value)}
          inputProps={{ maxLength: 50, style: { fontFamily: theme.typography.fontFamily } }}
          fullWidth
          margin="normal"
          variant="outlined"
          multiline
        />
        <TextField
          label="Description"
          value={description}
          onChange={e => setDescription(e.target.value)}
          inputProps={{ maxLength: 150, style: { fontFamily: theme.typography.fontFamily } }}
          fullWidth
          multiline
          margin="normal"
          variant="outlined"
          minRows={3}
        />
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default NewFeedback;
