// Importing necessary components and functions from packages and local files
import { Navigate } from 'react-router-dom'; // Navigate component from react-router for programmatic navigation
import { useAuth } from './AuthContext'; // useAuth custom hook from AuthContext.js for accessing the auth state

// Defining the PrivateWrapper component. This is a higher order component that wraps around other components to 
// check if the user is authenticated before allowing them to access those components. 
const PrivateWrapper = ({ children }) => {
  // useAuth hook is used to get the user's authentication status and loading state
  let { currentUser, loading } = useAuth();

  // If auth state is still loading (for example, Firebase is still trying to check the user's authentication state), 
  // we return null or a loading spinner.
  if (loading) {
    return null; // Or a loading spinner, if you wish.
  }

  // If no user is authenticated, we redirect them to the login page.
  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  // If the user is authenticated, we return the children components that the PrivateWrapper is wrapping.
  return children;
};

// Exporting PrivateWrapper for use in other files.
export default PrivateWrapper;
