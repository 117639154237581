// /Users/testviewer/projects/parentball/src/ContentContainer.js

import React from 'react';
import Container from '@mui/material/Container';

function ContentContainer({ children }) {
  return (
    <Container maxWidth="sm" disableGutters>
      {children}
    </Container>
  );
}

export default ContentContainer;
