/* creating a new component where an admin can enter the email of a 
potential user and send them an invitation. 
The handleInvite function uses the Firebase sendSignInLinkToEmail method 
to send an email with a sign-in link to the potential user. 
The link will redirect to the /signup route of our application. */

import React, { useState } from 'react';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
// import { useAuth } from './AuthContext';

function Invite() {
  // const { role } = useAuth(); // temp comment out. role never used
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const auth = getAuth();

  const handleInvite = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage("");
  
    let actionCodeSettings = {
      url: `${process.env.REACT_APP_URL}/signup?email=${encodeURIComponent(email)}`,
      handleCodeInApp: true,
    };
  
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(function() {
        setMessage(`Invitation sent to ${email}.`);
        setLoading(false);
      })
      .catch(function(error) {
        console.error(error);
        setMessage("Failed to send invitation. Please try again later.");
        setLoading(false);
      });
  };

  return (
    <div>
      <h1>Invite</h1> {/* Added h2 tag */}
      <form onSubmit={handleInvite}>
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          type="email"
          required
        />
        <Button variant="contained" color="primary" type="submit" disabled={loading}>
          Send Invitation
        </Button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default Invite;


