import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

function AdminWrapper({ children }) {
  const { currentUser, role } = useAuth();
  const [roleLoading, setRoleLoading] = useState(true); // Local state variable for role loading
  const navigate = useNavigate();

  // Run when `currentUser` or `role` changes
  useEffect(() => {
    if (currentUser && role !== null) {
      setRoleLoading(false); // Set roleLoading to false when currentUser is present and role is not null
    }
  }, [currentUser, role]);

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    } else if (!roleLoading && role !== 'superadmin') {
      navigate('/'); // Or wherever you want non-superadmins to be redirected
    }
  }, [currentUser, role, roleLoading, navigate]); // Add roleLoading as dependency

  return (!roleLoading && currentUser && role === 'superadmin') ? children : null;
}

export default AdminWrapper;
