import React, { useEffect, useState, createContext, useContext } from "react";
import { signOut } from "firebase/auth";
import { auth, isSignInWithEmailLink, signInWithEmailLink } from "./firebase";

export const AuthContext = createContext();

// Define the AuthProvider component.
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);

  // Define the function to fetch user role
  const fetchUserRole = async (user) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/get-user-role?uid=${user.uid}`);
    const data = await response.json();
    setRole(data.role);
  };

  useEffect(() => {
    // Checking if the URL has a Firebase email sign in link.
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Getting the stored email from local storage.
      let email = window.localStorage.getItem('emailForSignIn');
      
      // If the email is not in local storage, prompt the user to enter it.
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }

      // Try to sign in the user using the email link.
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          // Removing the email from local storage.
          window.localStorage.removeItem('emailForSignIn');
          // User signed in successfully, you can access the new user via result.user.
          setCurrentUser(result.user);
          if (result.user) {
            fetchUserRole(result.user);
          }
          setLoading(false);
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code and error.message
          console.error(error);
        });
    } else {
      return auth.onAuthStateChanged(user => {
        setCurrentUser(user);
        
        if (user) {
          fetchUserRole(user);
        }

        setLoading(false);
      });
    }
  }, []);

  // Function to handle logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null); // Set current user to null when logged out
      setRole(null); // Set role to null when logged out
    } catch (error) {
      console.error(error);
    }
  };

  const value = {
    currentUser,
    role,
    loading,
    handleLogout // Add handleLogout function to the context value
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
