import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import UsernameSelector from './UsernameSelector';
import axios from 'axios';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import LocationSelector from './LocationSelector';
import CustomSnackbar from './CustomSnackbar';
import Login from './Login'; 

const Profile = () => {
  const { currentUser } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [selectedUsername, setSelectedUsername] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState(null);
  const [userLocation, setUserLocation] = useState(null); // Added userLocation state variable
  // Declare a new state variable for the season start date
  const [seasonStartDate, setSeasonStartDate] = useState(null);
  // Declare a new state variable to control the display of the hint
  const [displayHint, setDisplayHint] = useState(false);

  // states for Snackbars
  const [usernameSnackbarOpen, setUsernameSnackbarOpen] = useState(false);
  const [locationSnackbarOpen, setLocationSnackbarOpen] = useState(false);

  useEffect(() => {
    if (currentUser) {
      // get the username from the backend server
      axios.get(`${process.env.REACT_APP_API_URL}/users/${currentUser.uid}`)
        .then(response => {
          setUsername(response.data.username); 
        })
        .catch(error => console.error('Error fetching username:', error));

      // get the location from the backend server
      axios.get(`${process.env.REACT_APP_API_URL}/users/${currentUser.uid}/location`)
        .then(response => {
          setUserLocation(response.data.location);
        })
        .catch(error => console.error('Error fetching location:', error));
      // COMMENT OUR 8/4/23
      // // get the role from firebase
      // currentUser.getIdTokenResult(true)  // Pass true to force-refresh the token
      //   .then((idTokenResult) => {
      //     setRole(idTokenResult.claims.role);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      // END COMMENT OUR 8/4/23
    }
  }, [currentUser]);

  // Fetch the season start date from the backend
  useEffect(() => {
    const fetchSeasonStartDate = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/settings`);
        setSeasonStartDate(new Date(response.data.seasonStart));
      } catch (error) {
        console.error('Error fetching season start date:', error);
      }
    };

    fetchSeasonStartDate();
  }, []);
  
  const handleUsernameSelect = (username) => {
    setSelectedUsername(username);
  };

  const handleUsernameSave = async () => {
    setLoading(true);
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/users/${currentUser.uid}`, { username: selectedUsername });
      if (response.data) {
        setUsername(response.data.username);
        setSelectedUsername(null);
        setOpen(false); // Close the Collapse once the username is saved
        setUsernameSnackbarOpen(true); // Open the Snackbar
      }
    } catch (error) {
      console.error('Error updating username:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCollapse = () => {
    // Check if the current date is after the season start date
    const currentDate = new Date();
    const seasonStartWithNoTime = new Date(Date.UTC(seasonStartDate.getUTCFullYear(), seasonStartDate.getUTCMonth(), seasonStartDate.getUTCDate() + 1));
    
    // To allow changing on the start date itself
    const currentDateWithNoTime = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()));
  
    if (seasonStartDate && currentDateWithNoTime > seasonStartWithNoTime) {
      // Display the hint and disable the "Change Username" button
      setDisplayHint(true);
    } else {
      // Toggle the Collapse as usual
      setOpen(!open);
    }
  };

  const handleLocationChange = (event, newValue) => {
    setLocation(newValue);

    if(newValue){
        axios.put(`${process.env.REACT_APP_API_URL}/location`, {

          uid: currentUser.uid,
          location: newValue.label
        })
        .then(response => {
          console.log(response.data.message);
          if (response.data.message) {
            setUserLocation(newValue.label); // Set the userLocation state variable here
            setLocationSnackbarOpen(true);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setUsernameSnackbarOpen(false);
    setLocationSnackbarOpen(false);
  };

  return (
    <div>
      <h1>Profile</h1>
      <p>Username: {username}</p>

      <Button 
        variant="contained" 
        color="secondary" 
        onClick={handleCollapse}
      >
        Change Username
      </Button>
      {displayHint && (
        <p style={{ color: 'grey' }}>😢 Sorry, can't change your name mid-season, it confuses the refs! Try again after the season ends. 🏀</p>
      )}

      <Collapse in={open || !username}>
        <UsernameSelector selectedUsername={selectedUsername} onUsernameSelect={handleUsernameSelect} />

        <Button variant="contained" color="primary" onClick={handleUsernameSave} disabled={loading || !selectedUsername}>
          {loading ? 'Saving...' : 'Save Username'}
        </Button>
      </Collapse>
      <br />
      
      <p>Location: {userLocation}</p> {/* Display the user's location here */}
      <LocationSelector location={location} onLocationChange={handleLocationChange} />
      <CustomSnackbar
        open={usernameSnackbarOpen}
        handleClose={handleCloseSnackbar}
        message="Username updated successfully!"
        severity="success"
      />
      <CustomSnackbar
        open={locationSnackbarOpen}
        handleClose={handleCloseSnackbar}
        message="Location updated successfully!"
        severity="success"
      />

      {/* Render the Login component */}
      <br />
      <Login />
    </div>
  );
}

export default Profile;
