import React from "react";
import Login from './Login';
import { Box } from '@mui/material';

function LoginPage() {
  return (
    <div className="page-container">
      <div className="content-container">
        <h1>Log In to Parentball</h1>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Login />
        </Box>
      </div>
    </div>
  );
}

export default LoginPage;
