import React, { useState, useEffect } from 'react';
import MultipleChoiceButtons from './MultipleChoiceButtons';
import usePageContent from './hooks/usePageContent';

export default function WarmupQuestion({ questionId }) {
    const [warmupQuestion, setWarmupQuestion] = useState(null);
    const [responseSubmitted, setResponseSubmitted] = useState(false);
    const { content, loading } = usePageContent("WarmupQuestion");

    useEffect(() => {
        if (questionId) {  // Make sure we have a valid questionId before fetching
            fetch(`${process.env.REACT_APP_API_URL}/warmup-question/${questionId}`)
                .then(response => response.json())
                .then(data => setWarmupQuestion(data))
                .catch(error => console.error('Error:', error));
        }
    }, [questionId]);

    if (!questionId || loading) {
        return null;  // If we don't have a questionId yet or if the content is still loading, don't render anything
    }

    if (!warmupQuestion || !warmupQuestion.options) {
        return <div></div>;
    }

    if (responseSubmitted) {
        return <div></div>;  // or return null;
    }

    return (
        <div>
          <h1>{content.h1[0]}</h1>
          <h2>{warmupQuestion.questionText}</h2>
          <MultipleChoiceButtons
            choices={warmupQuestion.options}
            onClick={() => setResponseSubmitted(true)}
          />
        </div>
    );
}
