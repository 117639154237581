// Medals.js
// Repalce with custom Parentball emojis in future

// 7/18/27 COMMENTING OUT
// eslint: "'React' is defined but never used"
// import React from 'react';

function Medals({ type }) {
  switch(type) {
    case 'gold':
      return '🥇';
    case 'silver':
      return '🥈';
    case 'bronze':
      return '🥉';
    default:
      return null;
  }
}

export default Medals;
