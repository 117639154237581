// StoryHeader.js

import React from 'react';
import Medals from './Medals';

function StoryHeader({ story, index, status, username, showRanking }) {
  // Function to get ordinal suffix (st, nd, rd, th) for a given number
  const getOrdinalSuffix = (n) => {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return s[(v - 20) % 10] || s[v] || s[0];
  };

  const medalTypes = ['gold', 'silver', 'bronze'];
  const medalType = status === 'results' ? medalTypes[index] : null;

  return (
    <h3>
      {status === 'results' && medalType && <Medals type={medalType} />}
      {status === 'voting' && showRanking && `(${index + 1}${getOrdinalSuffix(index + 1)})`} 
      {story.punnyTitle || ''} by {username || 'Username Not Set'}
    </h3>
  );
}

export default StoryHeader;
