import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import axios from 'axios';

const LocationSelector = ({ location, onLocationChange }) => {
  const [locationOptions, setLocationOptions] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/locations`);

        // Define the desired order for the first three items
        const desiredFirstItems = ['Boston', 'Massachusetts', 'United States of America'];

        // Sort the location options based on the desired order
        const sortedOptions = response.data.sort((a, b) => {
          // Check if both items are in the desired first items
          const aIndex = desiredFirstItems.indexOf(a.label);
          const bIndex = desiredFirstItems.indexOf(b.label);

          if (aIndex !== -1 && bIndex !== -1) {
            // Sort based on the desired first items order
            return aIndex - bIndex;
          } else if (aIndex !== -1) {
            // a should come before b
            return -1;
          } else if (bIndex !== -1) {
            // b should come before a
            return 1;
          }

          // Sort by label in the desired order
          if (a.type === 'city' && b.type !== 'city') return -1;
          if (a.type !== 'city' && b.type === 'city') return 1;
          if (a.type === 'state' && b.type === 'country') return -1;
          if (a.type === 'country' && b.type === 'state') return 1;

          // Sort alphabetically within each group
          return a.label.localeCompare(b.label);
        });

        setLocationOptions(sortedOptions);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };

    fetchLocations();
  }, []);

  return (
    <Autocomplete
      value={location}
      onChange={onLocationChange}
      options={locationOptions}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <li {...props}>
          <Typography variant="body1">
            {option.label} <Typography color="textSecondary" component="span"> ({option.type})</Typography>
          </Typography>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Change Location"
          variant="outlined"
          sx={{ '& .MuiInputBase-input': { fontFamily: 'Poppins, Lato, Open Sans, sans-serif' } }}
        />
      )}
    />
  );
};

export default LocationSelector;
