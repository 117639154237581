// PageContent.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, List, ListItem, ListItemText, Button, Box, Grid, Link } from '@mui/material';
import { ListItemSecondaryAction, IconButton, Typography } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { routesList } from './PageContentRoutes';
import HTMLField from './HTMLField';

const PageContent = () => {
  const [contents, setContents] = useState([]);
  const [editing, setEditing] = useState(null);  // Tracks the ID of the content being edited
  const [editedContent, setEditedContent] = useState({});  // Tracks changes to the edited content

  const handleEditChange = (event, contentId, field, index) => {
    const newContent = { ...editedContent };
    if (Array.isArray(newContent[field])) {
      newContent[field][index] = event.target.value;
    } else {
      newContent[field] = event.target.value;
    }
    setEditedContent(newContent);
  };

  const handleEditSubmit = async (event, contentId) => {
    event.preventDefault();
    try {
      // Send all the current values of editedContent in the put request
      await axios.put(`${process.env.REACT_APP_API_URL}/content/${contentId}`, { content: editedContent });
      setEditing(null);
      setEditedContent({}); // Reset editedContent state
      fetchContents();
    } catch (err) {
      console.error(err);
    }
  };

  const fetchContents = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/content`);
      setContents(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchContents();
  }, []);

  const createFields = (content, field, handleOnChange) => {
    if (Array.isArray(content[field])) {
      return content[field].map((text, i) => (
        <TextField
          key={`${field}-${i}`}
          name={`${field}-${i}`}
          label={`${field.charAt(0).toUpperCase() + field.slice(1)}-${i}`}
          defaultValue={text}
          fullWidth
          multiline
          autoGrow
          onChange={(e) => handleOnChange(e, content._id, field, i)}
        />
      ));
    } else if (typeof content[field] === 'object') {
      return Object.keys(content[field]).flatMap((subField) =>
        content[field][subField].map((text, i) => (
          <TextField
            key={`${field}-${subField}-${i}`}
            name={`${field}-${subField}-${i}`}
            label={`${field.charAt(0).toUpperCase() + field.slice(1)}-${subField.charAt(0).toUpperCase() + subField.slice(1)}-${i}`}
            defaultValue={text}
            fullWidth
            multiline
            autoGrow
            onChange={(e) => handleOnChange(e, content._id, `${field}.${subField}`, i)}
          />
        )),
      );
    } else {
        return (
            <HTMLField
            key={field}
            name={field}
            label={field.charAt(0).toUpperCase() + field.slice(1)}
            value={editedContent[field]}
            onChange={(e) => handleOnChange(e, content._id, field)}
            />
        );
        }
    };   

  return (
    <div>
      <h2>Page Content</h2>
      <Typography variant="body2" color="textSecondary">
      Changes you make here will immediately update the app for all users.<br/>
      <br/>
      You can view and edit the text for the Headlines (H1, H2, etc...), Paragraphs (P), Buttons (Btn),
      and Alerts (Succes, Warning, and Error). You can also update full html code in (Divs) for some 
      longer formatted blocks of text.<br/>
      <br/>
      Once you save a change to a page, click the link to the page. 
      A new tab will open and you can verify the changes are live.<br/>
      <br/>
      KNOWN ISSUE: Ediiting HTML in DIVs is adding an extra line break every place a line
      break exists. For now you need to manually remove the extra line breaks before saving.
      </Typography>
      <List>
        {contents.map((content) => (
          <ListItem key={content._id}>
            {editing === content._id ? (
              // If the content is being edited, display an editing form
              <Box component="form" onSubmit={(e) => handleEditSubmit(e, content._id)} sx={{ width: '100%' }}>
                {Object.keys(content.content).flatMap((field) =>
                  createFields(content.content, field, handleEditChange),
                )}
                <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
                  <Button variant="contained" color="primary" type="submit">
                    Save Changes
                  </Button>
                  <Button variant="contained" color="secondary" onClick={() => setEditing(null)} sx={{ ml: 2 }}>
                    Cancel
                  </Button>
                </Grid>
              </Box>
            ) : (
                // If the content is not being edited, display the content as usual
                <ListItemText
                    primary={
                        <Link href={getRoutePathByComponentName(content.page)} target="_blank" rel="noopener noreferrer">
                            {content.page}
                        </Link>
                    }
                secondary={
                  <div>
                    {Object.keys(content.content).flatMap((field) =>
                      Array.isArray(content.content[field])
                        ? content.content[field].map((text, i) => (
                            <div key={`${field}-${i}`}>
                              {`${field.charAt(0).toUpperCase() + field.slice(1)}-${i}: ${text}`}
                            </div>
                          ))
                        : typeof content.content[field] === 'object'
                        ? Object.keys(content.content[field]).flatMap((subField) =>
                            content.content[field][subField].map((text, i) => (
                              <div key={`${field}-${subField}-${i}`}>
                                {`${field.charAt(0).toUpperCase() + field.slice(1)}-${subField.charAt(0).toUpperCase() + subField.slice(1)}-${i}: ${text}`}
                              </div>
                            )),
                          )
                        : [
                            <div key={field}>
                              {`${field.charAt(0).toUpperCase() + field.slice(1)}: ${content.content[field]}`}
                            </div>,
                          ],
                    )}
                  </div>
                }
                secondaryTypographyProps={{ component: 'div' }}
              />
            )}

            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={() => {
                  setEditing(content._id);
                  setEditedContent(content.content);
                }}>
                <EditIcon />
              </IconButton>
            </ListItemSecondaryAction>
            </ListItem>
        ))}
      </List>
    </div>
  );
  // Function to get the route path by component name
  function getRoutePathByComponentName(componentName) {
    const route = routesList.find(route => route.componentName.toLowerCase() === componentName.toLowerCase());
    return route ? route.path : '#';
  }
};

export default PageContent;
