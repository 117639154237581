import React, { useState, useEffect } from 'react';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import CustomSnackbar from './CustomSnackbar';

const QuestionsPerSeason = () => {
  const [questionsPerSeason, setQuestionsPerSeason] = useState(0);
  const [newQuestionsPerSeason, setNewQuestionsPerSeason] = useState(0);
  const [confirmChange, setConfirmChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/settings`)
      .then(response => response.json())
      .then(data => setQuestionsPerSeason(data.questionsPerSeason));
  }, []);

  const handleChange = (e) => {
    setNewQuestionsPerSeason(e.target.value);
  };

  const handleConfirmation = (e) => {
    e.preventDefault();
    if (newQuestionsPerSeason !== 0) {
      setConfirmChange(true);
    } else {
      setSnackbarMessage('Please enter a valid number of questions.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/settings`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ questionsPerSeason: newQuestionsPerSeason }),
      });

      if (response.ok) {
        setSnackbarMessage('Questions per season successfully updated.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setConfirmChange(false);
        setLoading(false);
        window.location.reload();
      } else {
        setSnackbarMessage('There was an error updating the questions per season.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setConfirmChange(false);
        setLoading(false);
      }
    } catch (error) {
      setSnackbarMessage('There was an error updating the questions per season.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setConfirmChange(false);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setNewQuestionsPerSeason(0);
    setConfirmChange(false);
  };

  return (
    <>
      <h2>Questions Per Season: {questionsPerSeason}</h2>
      {!confirmChange ? (
        <form onSubmit={handleConfirmation}>
          <TextField
            label="New Questions Per Season"
            type="number"
            value={newQuestionsPerSeason}
            onChange={handleChange}
          />
          <Button 
            type="submit" 
            variant="contained" 
            color="primary" 
            disabled={loading || newQuestionsPerSeason === 0}
          >
            Update
          </Button>
        </form>
      ) : (
        <Dialog
          open={confirmChange}
          onClose={() => setConfirmChange(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirm Update</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to update the number of questions per season?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>  
      )}
      <CustomSnackbar 
        open={snackbarOpen} 
        handleClose={() => setSnackbarOpen(false)} 
        message={snackbarMessage} 
        severity={snackbarSeverity}
      />
    </>
  );
};

export default QuestionsPerSeason;
