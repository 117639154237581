// Import necessary modules
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button } from '@mui/material';
import axios from 'axios'; // Import axios as you do in EditStory.js

// Define the Story component
const Story = () => {
  // Get the story ID from the URL parameters
  const { id } = useParams();

  // Initialize the story state variable as null
  const [story, setStory] = useState(null);
  const [question, setQuestion] = useState(''); // Initialize question state variable

  // Use the useEffect hook to fetch the story data when the component mounts
  useEffect(() => {
    // Define an async function that fetches the story data
    const fetchStory = async () => {
      try {
        // Send a GET request to the story endpoint
        const response = await fetch(`${process.env.REACT_APP_API_URL}/story/${id}`);

        // If the response status is not ok, throw an error
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Parse the response body as JSON
        const storyData = await response.json();

        // Update the story state variable with the received data
        setStory(storyData.content);
      } catch (error) {
        // If an error occurred, log it to the console
        console.error("Fetching story failed:", error);
      }
    };

    // Call the fetchStory function
    fetchStory();
  }, [id]); // Pass the story ID as a dependency to the useEffect hook

  // Fetch the question of the day from the backend
  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/question-of-the-day`);
        setQuestion(response.data.content); // Update this line
      } catch (err) {
        console.error(err);
      }
    };
  
    fetchQuestion();
  }, []);

  // Return the JSX to render
  return (
    <div>
      <h1>Current Game</h1>
      <h2>{question}</h2>
      {story ? (
        <div>
          <p style={{whiteSpace: 'pre-wrap'}}>{story}</p> {/* Display the story content with paragraph breaks */}
          <Link to={`/edit-story/${id}`} style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary">Edit</Button> {/* Update Edit link to a button */}
          </Link>
        </div>
      ) : (
        // If the story data hasn't loaded yet, display a loading message
        <p></p>
      )}
    </div>
  );
};

// Export the Story component so it can be used in other files
export default Story;