// FeedbackButton.js

import React, { useState } from 'react';
import { Fab, Typography } from '@mui/material';
import Feedback from './Feedback';  // import the Feedback component

const FeedbackButton = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Fab 
        variant="extended"
        color="default" 
        aria-label="feedback" 
        sx={{
          position: 'fixed',
          right: '-24px',  // Offset by the global padding
          top: '75vh', 
          transform: 'rotate(-90deg)',
          opacity: 0.5,
          borderRadius: 0,
        }}
        onClick={handleOpen}
      >
        <Typography variant="button">
          Feedback
        </Typography>
      </Fab>
      <Feedback open={open} handleClose={handleClose} />
    </>
  );
};

export default FeedbackButton;
