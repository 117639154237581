// StartDate.js

import React, { useState, useEffect } from 'react';
import { TextField, Button } from '@mui/material';
import moment from 'moment-timezone';
import Typography from "@mui/material/Typography";
import CustomSnackbar from './CustomSnackbar'; // replace './CustomSnackbar' with the correct path to your CustomSnackbar component

const StartDate = () => {
  const [startDate, setStartDate] = useState(null);
  const [editing, setEditing] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const fetchStartDate = () => {
    fetch(`${process.env.REACT_APP_API_URL}/settings/details`)
      .then(response => response.json())
      .then(data => {
        if(data.seasonStart) {
          const date = new Date(data.seasonStart);
          const dateString = date.toISOString().split('T')[0];  // Format as "YYYY-MM-DD" for input field
          setStartDate(dateString);
        }
      });
  }

  useEffect(fetchStartDate, []);

  const handleChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
    fetchStartDate();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (startDate) {
      try {
        // Use moment to set the time to 3:00 AM New York time
        const newStartDate = moment.tz(startDate, 'America/New_York').startOf('day').add(3, 'hours').toISOString();
  
        const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/seasonStart`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ seasonStart: newStartDate }),
        });

        if (response.ok) {
          setSnackbarMessage('Start date successfully updated.');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          setEditing(false);
          window.location.reload(); // Refresh the page
        } else {
          setSnackbarMessage('There was an error updating the start date.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      } catch (error) {
        setSnackbarMessage('There was an error updating the start date.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } else {
      setSnackbarMessage('Please enter a date.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
    }
  };

  // function to format the date as DAY_OF_WEEK, MONTH DAY, YEAR
  const formatDate = (dateString) => {
    // COMMENTING OUT for eventual deletion. Unused function.
    // const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    // Create a moment object with the date string
    let date = moment(dateString);
    // Convert the time to New York time zone
    date = date.tz('America/New_York');
    return date.format('dddd, MMMM DD, YYYY'); // Use moment's format function
  };
  
  return (
    <div>
      <h2>Season Start Date</h2>
      {!editing ? (
        <div>
          <p>{startDate ? formatDate(startDate) : 'Loading...'}</p>
          <Button variant="contained" color="primary" onClick={handleEdit}>Edit</Button>
          <Typography variant="caption" display="block" gutterBottom>
            A change made here will automatically update the Queston Intervals below.
            After you click Edit and Update the date here, you must click the Set Schedule button below for the changes to take effect.
          </Typography>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <TextField
            label="Start Date"
            type="date"
            value={startDate || ''}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button variant="contained" color="primary" type="submit">Update</Button>
          <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
        </form>
      )}
      <CustomSnackbar 
        open={snackbarOpen} 
        handleClose={() => setSnackbarOpen(false)} 
        message={snackbarMessage} 
        severity={snackbarSeverity}
      />
    </div>
  );
};

export default StartDate;

