// Filename: UpdateNullUsernames.js
import axios from 'axios';
import { generateUsernames } from './usernames';
import { Button } from '@mui/material';
import React, { useState } from 'react';
import CustomSnackbar from './CustomSnackbar';

const UpdateNullUsernames = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const assignUsernamesToNullUsers = async () => {
    try {
      // Fetch all users with null usernames from the backend
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/nullusernames`);

      if (response.data.message) {
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
        return; // Early return if no users found
      }

      const nullUsernameUsers = response.data;

      // For each user without a username, generate a new username and assign it
      let updatedCount = 0;
      for (let user of nullUsernameUsers) {
        const generatedUsername = generateUsernames()[0];  // Generate one username

        const updateResponse = await axios.put(`${process.env.REACT_APP_API_URL}/users/${user.uid}`, { username: generatedUsername })
          .catch(error => {
            console.error('Error updating username:', error);
            setSnackbarMessage('Error updating username');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
          });

        if (updateResponse && updateResponse.data.username) {
          updatedCount++;
        }
      }

      setSnackbarMessage(`Auto assigned usernames for ${updatedCount} users.`);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

    } catch (error) {
      console.error('Error fetching users:', error);
      setSnackbarMessage('Error fetching users');
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <h2>Update Null Usernames</h2>
      <p>Adds a username to any user who does not have one.</p>
      <Button variant="contained" color="primary" onClick={assignUsernamesToNullUsers}>
        Update null usernames
      </Button>
      <CustomSnackbar open={snackbarOpen} handleClose={handleCloseSnackbar} message={snackbarMessage} severity={snackbarSeverity} />
    </>
  );
};

export default UpdateNullUsernames;
