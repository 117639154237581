//CloseButton.js

import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const CloseButton = ({ onClick }) => {
  return (
    <IconButton edge="start" color="inherit" aria-label="close" onClick={onClick}>
      <CloseIcon fontSize="large" />
    </IconButton>
  );
};

export default CloseButton;
