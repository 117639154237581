import React, { useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomSnackbar from './CustomSnackbar';


const CheckUserRole = () => {
  const [email, setEmail] = useState('');
  const [userRole, setUserRole] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [open, setOpen] = useState(false);

  const handleUserRole = async (event) => {
    event.preventDefault();

    const encodedEmail = encodeURIComponent(email);

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-role-by-email?email=${encodedEmail}`);
      setUserRole(response.data.role);
      setFeedback('User role fetched successfully.');
      setOpen(true);
    } catch (err) {
      setUserRole(null);
      setFeedback(err.response?.data?.message || 'An error occurred.');
      setOpen(true);
    }
  };

  const updateUserRole = async (newRole) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/user-role-by-email`, {
        email: email,
        role: newRole,
      });
      setUserRole(newRole);
      setFeedback('User role updated successfully.');
      setOpen(true);
    } catch (err) {
      setFeedback(err.response?.data?.message || 'An error occurred.');
      setOpen(true);
    }
  };

  const handleSwitchChange = (event) => {
    const newRole = event.target.checked ? 'commissioner' : 'player';
    updateUserRole(newRole);
  };

  const isStandardRole = (role) => {
    return ['player', 'commissioner'].includes(role);
  };

  return (
    <Box
      component="form"
      onSubmit={handleUserRole}
      sx={{
        '& > :not(style)': { m: 1 },
      }}
      noValidate
      autoComplete="off"
    >
      <h2>Change User Role</h2>
      <TextField 
        id="outlined-basic" 
        label="Email" 
        variant="outlined" 
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button variant="contained" type="submit">Check Role</Button>
      {userRole && (
        <div>
          <span>User Role: </span>
          {isStandardRole(userRole) ?
            <FormControlLabel
              control={
                <Switch 
                  checked={userRole === 'commissioner'} 
                  onChange={handleSwitchChange}
                  name="userRole" 
                  color="primary"
                />
              }
              label={userRole === 'commissioner' ? 'Commissioner' : 'Player'}
            /> :
            <p>{userRole}</p>}
        </div>
      )}
      <CustomSnackbar
        open={open}
        handleClose={() => setOpen(false)}
        message={feedback}
        severity={feedback === 'User role updated successfully.' || feedback === 'User role fetched successfully.' ? 'success' : 'error'}
      />
    </Box>
  );
};

export default CheckUserRole;
