// NextQuestionMessage.js

import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import axios from 'axios';
import usePageContent from './hooks/usePageContent';

const NextQuestionMessage = ({ isFirstQuestion }) => {
let navigate = useNavigate();
const [nextQuestionDate, setNextQuestionDate] = useState('');
const { content, loading } = usePageContent('NextQuestionMessage');

useEffect(() => {
    const fetchNextQuestionDate = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/next-question`);
        const utcDate = moment.utc(response.data.submissionDate).format('YYYY-MM-DD');
        const localDate = moment.tz(utcDate, 'America/New_York');
        setNextQuestionDate(localDate.format());
    } catch (err) {
        console.error(err);
    }
    };

    fetchNextQuestionDate();
}, []);

if (loading || !content) {
    return <p>Loading...</p>;
}

const date = moment(nextQuestionDate).isValid() 
    ? moment(nextQuestionDate).format('dddd, MMMM Do') 
    : "Invalid Date";
const message = content.h1[isFirstQuestion ? 0 : 1].replace('{date}', date);
const h2Text = content.p[isFirstQuestion ? 0 : 1].replace('{date}', date);
const pText = content.p[isFirstQuestion ? 2 : 3];
const buttonText = content.btn[isFirstQuestion ? 0 : 1];
const buttonLink = isFirstQuestion ? "/profile" : "/current-game";

return (
    <Box
    sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        bgcolor: 'background.default',
        px: 2,
    }}
    >
    <h1>{message}</h1>
    <h2>{h2Text}</h2>
    <Typography variant="body1" component="p" gutterBottom>
        {pText}
    </Typography>
    <Button
        variant="contained"
        color="primary"
        onClick={() => navigate(buttonLink)}
        sx={{mt: 4}}
        >
        {buttonText}
    </Button>
    </Box>
);
};

export default NextQuestionMessage;
