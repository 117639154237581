import React from 'react';
import { Typography, Box } from '@mui/material';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import theme from './theme';
import usePageContent from './hooks/usePageContent';

// Extract colors from theme
const primaryColor = theme.palette.primary.main;
const secondaryColor = theme.palette.secondary.main;

// Function to lighten or darken color
const adjustColor = (color, amount) => {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

// Prepare colors array
const confettiColors = [
    primaryColor, 
    secondaryColor,
    adjustColor(primaryColor, -20),
    adjustColor(primaryColor, 20),
    adjustColor(secondaryColor, -20),
    adjustColor(secondaryColor, 20),
];

// Define the WaitlistConfirmation component
const WaitlistConfirmation = () => {
    // Get the window size for confetti
    const { width, height } = useWindowSize();
    const { content, loading } = usePageContent("WaitlistConfirmation");

    return loading ? null : (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                textAlign: 'center',
                bgcolor: 'background.default',
                px: 2,
            }}
        >
            <Confetti 
                width={width} 
                height={height} 
                colors={confettiColors}
            />
            <h1>{content.h1[0]}</h1>
            <h2>{content.h2[0]}</h2>
            <Typography variant="body1" component="p" gutterBottom>
                {content.p[0]}
            </Typography>
            <h2>{content.h2[1]}</h2>
        </Box>
    );
};

export default WaitlistConfirmation;
