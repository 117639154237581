import React from 'react'; // Remove useEffect import
// Import necessary hooks from React and react-router-dom.
// useNavigate is a hook from react-router-dom which lets you navigate between routes.
import { useNavigate } from 'react-router-dom';

function ConfirmEmail() {
  // Define the navigate function using the useNavigate hook.
  // This function will be used to navigate to the home page after sign-in.
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate('/');
  }, [navigate]);

  return <p>Confirming your email...</p>;
}

export default ConfirmEmail;

