// SetScheduleButton.js

import React, { useState } from 'react';
import { Button, Tooltip, Typography } from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CustomSnackbar from './CustomSnackbar';

const SetScheduleButton = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const handleClick = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/set-schedule`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setMessage('Schedule successfully set.');
        setSeverity('success');
      } else {
        setMessage('There was an error setting the schedule.');
        setSeverity('error');
      }
    } catch (error) {
      setMessage('There was an error setting the schedule.');
      setSeverity('error');
    }

    setOpen(true);
  };

  return (
    <div>
      <h2>Set Schedule</h2>

      <Typography variant="caption" display="block" gutterBottom>
        You Must click this button if you change the Season Start Date or Question Intervals above. Doing so will update all questions with the new submission, voting, and results dates, and ensure automatic emails are sent correctly.
      </Typography>

      <Tooltip title="Click to set the schedule for the current season">
        <Button
          variant="contained"
          color="primary"
          startIcon={<ScheduleIcon />}
          onClick={handleClick}
          sx={{ fontSize: '2rem' }}  // Makes the button text twice the normal size
        >
          Set Schedule
        </Button>
      </Tooltip>

      <CustomSnackbar open={open} handleClose={() => setOpen(false)} message={message} severity={severity} />
    </div>
  );
};

export default SetScheduleButton;
