// Standings.js

import React, { useEffect, useState } from 'react';
import { useSortableData } from './hooks/useSortableData';
import Medals from './Medals';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Button from '@mui/material/Button';
import usePageContent from './hooks/usePageContent';  // new import

const Standings = () => {
  const [standingsData, setStandingsData] = useState([]);
  const { content, loading: contentLoading } = usePageContent('standings');  // new usage of usePageContent hook
  const { items, requestSort, sortConfig } = useSortableData(standingsData, 'gold', 'desc');

  useEffect(() => {
    const fetchSeasonResults = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/vote/season-results`);
        const data = await response.json();

        // Sort data by specific criteria before setting in state
        data.sort((a, b) => {
          if (a.gold !== b.gold) return b.gold - a.gold;
          if (a.silver !== b.silver) return b.silver - a.silver;
          if (a.bronze !== b.bronze) return b.bronze - a.bronze;
          return a.username.localeCompare(b.username);
        });

        setStandingsData(data);
      } catch (error) {
        console.error('Error fetching season results:', error);
      }
    };

    fetchSeasonResults();
  }, []);

  if (contentLoading) return null;  // Add this line

  return (
    <>
      <h1>{content.h1[0]}</h1>
      {standingsData.length === 0
        ? content.p.map((paragraph, index) => <p key={index}>{paragraph}</p>)  // Change this line
        : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{paddingRight: 0.5, paddingLeft: 0.5}} sortDirection={sortConfig.key === 'username' ? sortConfig.direction : false}>
                    <Button onClick={() => requestSort('username')} endIcon={<UnfoldMoreIcon />}>
                      Username
                    </Button>
                  </TableCell>
                  <TableCell sx={{width: '10%', paddingRight: 0.5, paddingLeft: 0.5}} align="right" sortDirection={sortConfig.key === 'gold' ? sortConfig.direction : false}>
                    <Button onClick={() => requestSort('gold')} endIcon={<UnfoldMoreIcon />}>
                      <Medals type="gold" />
                    </Button>
                  </TableCell>
                  <TableCell sx={{width: '10%', paddingRight: 0.5, paddingLeft: 0.5}} align="right" sortDirection={sortConfig.key === 'silver' ? sortConfig.direction : false}>
                    <Button onClick={() => requestSort('silver')} endIcon={<UnfoldMoreIcon />}>
                      <Medals type="silver" />
                    </Button>
                  </TableCell>
                  <TableCell sx={{width: '10%', paddingRight: 0.5, paddingLeft: 0.5}} align="right" sortDirection={sortConfig.key === 'bronze' ? sortConfig.direction : false}>
                    <Button onClick={() => requestSort('bronze')} endIcon={<UnfoldMoreIcon />}>
                      <Medals type="bronze" />
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((row) => (
                    <TableRow key={row.username}>
                        <TableCell sx={{paddingRight: 0.5, paddingLeft: 1.5}} component="th" scope="row">{row.username}</TableCell>
                        <TableCell sx={{width: '10%', paddingRight: 0.5, paddingLeft: 0.5}} align="center">{row.gold}</TableCell>
                        <TableCell sx={{width: '10%', paddingRight: 0.5, paddingLeft: 0.5}} align="center">{row.silver}</TableCell>
                        <TableCell sx={{width: '10%', paddingRight: 0.5, paddingLeft: 0.5}} align="center">{row.bronze}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
    </>
  );
};

export default Standings;
