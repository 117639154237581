// Import the React library and useState hook
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import axios from 'axios'; // Import axios library
import WarmupQuestion from './WarmupQuestion'; // Import the WarmupQuestion component.
import { auth } from './firebase';  // Import authenticated user from Firebase
import { Button, Paper, useTheme } from '@mui/material'; // Import Paper and useTheme from MUI
import { redactPii } from './PiiRedaction'; // Import the redactPii function
import NextQuestionMessage from './NextQuestionMessage';
import usePageContent from './hooks/usePageContent'; // Import the usePageContent hook
// import AutoUsernameAssigner from './AutoUsernameAssigner';
import { generateUsernames } from './usernames';

//BEGIN ADDED 07/21/2023 TO ENSURE FIRST TIME USER HAS MONGODB USER DOC CREATED AND IS ADDED TO SG
import { useAuth } from './AuthContext';
//END ADDED 07/21/2023 TO ENSURE FIRST TIME USER HAS MONGODB USER DOC CREATED AND IS ADDED TO SG

// Define the StorySubmission functional component
const StorySubmission = () => {
  const { currentUser } = useAuth();
  const { content, loading: contentLoading } = usePageContent('StorySubmission'); // usePageContent hook

  useEffect(() => {
    if (currentUser) {
      // Replace this URL with your actual server URL if it's running on a different port or domain
      const serverUrl = process.env.REACT_APP_API_URL;

      axios.get(`${serverUrl}/users/${currentUser.uid}`)
        .then(res => {
          // If the user does not exist in MongoDB, create a new document
          if (res.status === 404) {
            const generatedUsername = generateUsernames()[0];  // Generate one username
            axios.post(`${serverUrl}/signup`, {
              uid: currentUser.uid,
              email: currentUser.email,
              username: generatedUsername,  // Use the generated username
            })
              .then(() => console.log('User document created'))
              .catch(err => console.error('Error creating user document:', err));
          } else if (!res.data.username) {  // If the user exists but has no username
            const generatedUsername = generateUsernames()[0];  // Generate one username
            axios.put(`${serverUrl}/users/${currentUser.uid}`, { username: generatedUsername })
              .then(() => console.log('Username auto assigned'))
              .catch(err => console.error('Error auto assigning username:', err));
          }
        })
        .catch(err => {
          console.error('Error fetching user document:', err);
          
          // Verify that the error is a 404 (user not found)
          if (err.response && err.response.status === 404) {
            const generatedUsername = generateUsernames()[0];  // Generate one username
            axios.post(`${serverUrl}/signup`, {
              uid: currentUser.uid,
              email: currentUser.email,
              username: generatedUsername,  // Use the generated username
            })
            .then(() => console.log('User document created'))
            .catch(err => console.error('Error creating user document:', err));
          }
        });
    }
  }, [currentUser]); // Re-run this effect when currentUser changes
  //END ADDED 07/21/2023 TO ENSURE FIRST TIME USER HAS MONGODB USER DOC CREATED AND IS ADDED TO SG
  
  // Add a new state variable for the question of the day
  const [question, setQuestion] = useState('');

  // Add a new state variable for the id of the question of the day
  const [questionId, setQuestionId] = useState('');
  
  // Declare a new state variable for the story, initialized as an empty string
  const [story, setStory] = useState('');

  // Declare a new state variable for the number of rows in the textarea, initialized as 4
  const [rows, setRows] = useState(10);

  // Declare a new state variable for the length of the story, initialized as 0
  const [storyLength, setStoryLength] = useState(0);

  // Add the state variable for redaction options here
  const [showRedactionOptions, setShowRedactionOptions] = useState(false);

  let navigate = useNavigate(); // call useNavigate here to access the history object
  const theme = useTheme(); // use MUI theme

  // Declare a new state variable for the redacted words, initialized as an empty array
  const [redactedWords, setRedactedWords] = useState([]);

  // Declare a new state variable for the nickname map
  const [nicknameMap, setNicknameMap] = useState({});

  // Declare a new state variable for the clicked words, initialized as an empty set
  const [clickedWords, setClickedWords] = useState(new Set());

  // Declare a new state variable for the ignored words, initialized as an empty set
  const [ignoredWords, setIgnoredWords] = useState(new Set());

  // Create a reference to the redaction options
  const redactionRef = useRef(null);

  // Scroll to the redaction options when they are shown
  useEffect(() => {
    if (showRedactionOptions && redactedWords.length > 0 && redactionRef.current) {
      redactionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showRedactionOptions, redactedWords]);

  // Modify the handleStoryChange function to update the nickname map
  const handleStoryChange = (event) => {
    if (event.target.value.length > 1000) return;
  
    // Do not check for redaction if the last character is not a space
    if (event.target.value[event.target.value.length - 1] !== ' ') {
      setStory(event.target.value);
      setStoryLength(event.target.value.length);
      if (event.target.value.length > 280 && rows < 16) {
        setRows(rows + 12);
      }
      return;
    }
  
    setStory(event.target.value);
    setStoryLength(event.target.value.length);
    if (event.target.value.length > 280 && rows < 16) {
      setRows(rows + 12);
    }
    const redactedStory = redactPii(event.target.value);
    setNicknameMap(redactedStory.nicknameMap);  // <-- Add this line
    const newRedactedWords = redactedStory.replacements
      .map((rep) => rep.original)
      .filter(word => !ignoredWords.has(word));  // Filter out the ignored words
    setRedactedWords(newRedactedWords);
  }; 

  // Add a new state variable for the next question date
  const [nextQuestionDate, setNextQuestionDate] = useState('');
  
  // Add a new state variable for the id of the submitted story
  const [submittedStoryId, setSubmittedStoryId] = useState('');

  // Add a new state variable for checking if the next question is the first question
  const [isFirstQuestion, setIsFirstQuestion] = useState(false);

  // Fetch the question of the day from the backend
  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/question-of-the-day`);
    
        // Check if the response status is 404 (Not Found)
        if (response.status === 404) {
          const nextQuestionResponse = await axios.get(`${process.env.REACT_APP_API_URL}/next-question`);
          setNextQuestionDate(nextQuestionResponse.data.submissionDate);
        } else if (response.data.content) {
          setQuestion(response.data.content);
          setQuestionId(response.data._id);
          
          // Check if a story already exists for this user and question
          const storyResponse = await axios.get(`${process.env.REACT_APP_API_URL}/user-story?userId=${auth.currentUser.uid}&questionId=${response.data._id}`);
          if (storyResponse.data) {
            setStory(storyResponse.data.content);
          }
        }
      } catch (err) {
        // Only log the error if the status code is not 404
        if (err.response && err.response.status !== 404) {
          console.error(err);
        }
      }
    };
  
    fetchQuestion();
  }, []);

  /* IMPORTANT This will return
    GET http://localhost:3001/question-of-the-day 404 (Not Found)
    in the console is there is no current question of the day
    This is expected.*/

  // Fetch the user's submitted story for the current question
  useEffect(() => {
    const fetchUserStory = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-story?userId=${auth.currentUser.uid}&questionId=${questionId}`);
        
        if (response.data) {
          setSubmittedStoryId(response.data._id);
        }
      } catch (err) {
        console.error(err);
      }
    };
    
    if (questionId) {
      fetchUserStory();
    }
  }, [questionId]);
  
  useEffect(() => {
    const fetchIsFirstQuestion = async () => {
      try {
        const questionResponse = await axios.get(`${process.env.REACT_APP_API_URL}/next-question`);
        setIsFirstQuestion(questionResponse.data.sequence === 1);
      } catch (err) {
        console.error(err);
      }
    };
    
    fetchIsFirstQuestion();
  }, []);

  // Define a function that handles submission of the form
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    let finalStory = story; // define a new variable to hold the final story text
    
    // Only run the redaction process if showRedactionOptions is false
    if (!showRedactionOptions) {
      const redactedStory = redactPii(story);
    
      if (redactedWords.length > 0) {
        // Store the redaction options in the state variables
        setNicknameMap(redactedStory.nicknameMap);
        const newRedactedWords = redactedStory.replacements
          .map((rep) => rep.original)
          .filter(word => !ignoredWords.has(word));
        setRedactedWords(newRedactedWords);
    
        // Show the redaction options
        setShowRedactionOptions(true);
    
        return;
      }
    
      // Set the final story text to the redacted text
      finalStory = redactedStory.text;
    }
    
    // Submit the story
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/submit-story`, {
        content: finalStory, // use finalStory here instead of redactedStory.text
        userId: auth.currentUser.uid, 
        questionId: questionId 
      });
    
      if (response.status === 200) {
        navigate(`/thank-you/${response.data._id}`);
      } else {
        throw new Error(`Request failed: ${response.status}`);
      }
    } catch (error) {
      console.error("Submitting story failed:", error);
    }
  };

  // Return the JSX that will be rendered for this component
  return (
    <div>
      {content && content.h1 && <h1>{content.h1[0]}</h1>} {/* Check that content and content.h1 exist */}
      {contentLoading ? null :
      submittedStoryId ? (
        navigate(`/thank-you/${submittedStoryId}`)
      ) : question ? (
        <>
          <WarmupQuestion questionId={questionId} />
  
          {content && content.h1 && <h1>{content.h1[1]}</h1>} {/* Check that content and content.h1 exist */}
          <h2>{question}</h2>
  
          <form onSubmit={handleSubmit}>
            <Paper
              component="div"
              style={{
                width: '100%',
                padding: '16px',
                margin: '16px 0',
                backgroundColor: theme.palette.background.paper
              }}
            >
              <textarea
                id="story"
                name="story"
                value={story}
                onChange={handleStoryChange}
                rows={rows}
                placeholder={content.div}
                style={{
                  width: '100%',
                  fontSize: '1rem',
                  fontFamily: theme.typography.fontFamily,
                  lineHeight: theme.typography.body1.lineHeight,
                  letterSpacing: theme.typography.body1.letterSpacing,
                  boxSizing: 'border-box'
                }}
                />
                  <p dangerouslySetInnerHTML={{ __html: content.p[0] }}></p>
            </Paper>
            {showRedactionOptions && redactedWords.length > 0 && (
              <div ref={redactionRef}>
                <p style={{ color: 'red' }}>
                  Parentbot has detected a few names in your story. Have a look below and decide if you want to stick with them or swap them out for something more intriguing. Beep boop.
                </p>
                {redactedWords.map((word) => (
                  <div key={word}>
                    <p style={{ color: 'red' }}>{word}</p>
                    <button 
                      type="button" 
                      onClick={() => {
                        setStory(story.replace(new RegExp(`\\b${word}\\b`, 'g'), `[${nicknameMap[word]}]`));
                        setRedactedWords(redactedWords.filter(w => w !== word));
                        setClickedWords(new Set([...clickedWords, word]));
                      }}
                      disabled={clickedWords.has(word)}
                    >
                      Change {word} to {nicknameMap[word]}
                    </button>
                    <Button 
                      variant="outlined" 
                      color="secondary" 
                      type="button" 
                      onClick={() => {
                        setRedactedWords(redactedWords.filter(w => w !== word));
                        setClickedWords(new Set([...clickedWords, word]));
                        setIgnoredWords(new Set([...ignoredWords, word]));
                      }}
                      style={{ marginLeft: '10px' }}
                      disabled={clickedWords.has(word)}
                    >
                      Keep as is
                    </Button>
                  </div>
                ))}
              </div>
            )}
            <p>{storyLength}/1000</p>
            <Button 
              variant="contained" 
              color="primary" 
              type="submit"
            >
              Submit
            </Button>
          </form>
          </>
      ) : (
        <NextQuestionMessage isFirstQuestion={isFirstQuestion} nextQuestionDate={nextQuestionDate} />
      )}
    </div>
  );
};  

export default StorySubmission;
