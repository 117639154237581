import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';

const EmailEngagement = () => {
  const [emailEvents, setEmailEvents] = useState([]);
  const [usernames, setUsernames] = useState({}); // New state variable to store usernames
  const theme = useTheme();
  const borderColor = theme.palette.divider;

  const fetchEmailEvents = async (singlesend_id, email) => {
    try {
      let url = `${process.env.REACT_APP_API_URL}/sendgrid/events`;
      if (singlesend_id || email) {
        url += '?';
        if (singlesend_id) url += `singlesend_id=${singlesend_id}&`;
        if (email) url += `email=${email}`;
      }

      const response = await fetch(url);
      const data = await response.json();
      setEmailEvents(data);
    } catch (err) {
      console.error('Error fetching email events:', err);
    }
  };

  // New function to fetch usernames
  const fetchUsernames = async (emails) => {
    const usernamesMap = {};
    for (const email of emails) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/get-username-by-email?email=${encodeURIComponent(email)}`);
        const data = await response.json();
        if (data.username) {
          usernamesMap[email] = data.username;
        } else {
          // Handle specific error messages from the backend
          usernamesMap[email] = data.message;
        }
      } catch (err) {
        console.error('Error fetching username:', err);
      }
    }
    setUsernames(usernamesMap);
  };

  useEffect(() => {
    fetchEmailEvents(); // Pass singlesend_id and email as needed
  }, []);

  useEffect(() => {
    const emails = [...new Set(emailEvents.map((event) => event.email))]; // Get unique email addresses
    fetchUsernames(emails); // Fetch usernames for unique email addresses
  }, [emailEvents]);

  // Custom sorting function to order the singlesendInfo
  /* First sort by Season and Question number
  S0Q1, S0Q2, ..., S0Q10, S1Q1, S1Q2, ..., S1Q10, ...
  Then sort by Submission, Voting, Results */
  const sortSinglesendInfo = (a, b) => {
    const order = ['Submission', 'Voting', 'Results'];
    const nameA = a.singlesend_name.split(' ').slice(1).join(' ');
    const nameB = b.singlesend_name.split(' ').slice(1).join(' ');
  
    // Compare S#Q# pattern
    if (a.singlesend_name.slice(0, 4) < b.singlesend_name.slice(0, 4)) return -1;
    if (a.singlesend_name.slice(0, 4) > b.singlesend_name.slice(0, 4)) return 1;
  
    // Compare based on predefined order and additional text
    for (const keyword of order) {
      const isAKeyword = nameA.startsWith(keyword);
      const isBKeyword = nameB.startsWith(keyword);
      if (isAKeyword && isBKeyword) {
        if (nameA === keyword && nameB !== keyword) return -1;
        if (nameA !== keyword && nameB === keyword) return 1;
        return 0;
      }
      if (isAKeyword) return -1;
      if (isBKeyword) return 1;
    }
  
    // Default comparison for other strings
    return a.singlesend_name.localeCompare(b.singlesend_name);
  };  

  // Get a list of unique singlesend_ids and names
  const singlesendInfo = emailEvents.reduce((acc, event) => {
    if (!acc.find((info) => info.singlesend_id === event.singlesend_id)) {
      acc.push({
        singlesend_id: event.singlesend_id,
        singlesend_name: event.singlesend_name,
      });
    }
    return acc;
  }, []).sort(sortSinglesendInfo); // Sorting the array based on custom comparator

  // Group events by email address
  const groupedEmailEventsObj = emailEvents.reduce((acc, event) => {
    if (!acc[event.email]) {
      acc[event.email] = singlesendInfo.map((info) => ({
        singlesend_id: info.singlesend_id,
        events: {
          delivered: false,
          open: false,
          click: false,
        },
      }));
    }

    const matchedEvent = acc[event.email].find((e) => e.singlesend_id === event.singlesend_id);
    if (matchedEvent) {
      matchedEvent.events = event.events;
    }

    return acc;
  }, {});

  // Convert groupedEmailEvents to an array and sort by email address
  const groupedEmailEvents = Object.entries(groupedEmailEventsObj)
  .sort((a, b) => a[0].localeCompare(b[0]));

  // Calculate totals for each column (D, O, C)
  const totalDelivered = Array(singlesendInfo.length).fill(0);
  const totalOpened = Array(singlesendInfo.length).fill(0);
  const totalClicked = Array(singlesendInfo.length).fill(0);

  groupedEmailEvents.forEach(([_, events]) => {
    events.forEach((event, index) => {
      if (event.events.delivered) totalDelivered[index]++;
      if (event.events.open) totalOpened[index]++;
      if (event.events.click) totalClicked[index]++;
    });
  });

  // Calculate overall totals for each email
  const emailTotals = groupedEmailEvents.map(([_, events]) => ({
    totalDelivered: events.reduce((sum, event) => sum + (event.events.delivered ? 1 : 0), 0),
    totalOpened: events.reduce((sum, event) => sum + (event.events.open ? 1 : 0), 0),
    totalClicked: events.reduce((sum, event) => sum + (event.events.click ? 1 : 0), 0),
  }));

  return (
    <>
      <h2>Email Engagement</h2>
      <div style={{ color: theme.palette.grey[500], fontSize: '0.875rem' }}> {/* Updated font size */}
        This shows events for Single Send emails sent by Sendgrid.<br/>
        D = Delivered, O = Opened, C = Clicked.<br/>
        If a username is not found for a given email address, it could have one of three reasons:
        <ul style={{ margin: 0, padding: '0 1rem' }}> {/* Adjusted margins and padding */}
          <li><strong>'No User in Firebase':</strong> There is no user record found in Firebase Authentication for the given email address. 
            This almost certainly means the email address has <strong>never logged in</strong> to Parentball.</li>
          <li><strong>'No Doc in DB for UID':</strong> There is a user record in Firebase for the given email address. Each Firebase record has a unique User UID. But there is no corresponding User document in the database for this User UID. Only a bug would cause this. Please report it.</li>
          <li><strong>'No Username in DB Doc':</strong> The user document is found in the database, but the username field is missing, blank, or undefined. Only a bug would cause this. Please report it.</li>
        </ul>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', borderRight: `1px solid ${borderColor}` }}>Email Address</TableCell>
              <TableCell sx={{ fontWeight: 'bold', borderRight: `1px solid ${borderColor}` }}>Username</TableCell>
            {singlesendInfo.map((info, index) => (
              <React.Fragment key={info.singlesend_id}>
                <TableCell colSpan={3} align="center" sx={{ fontWeight: 'bold', borderRight: index < singlesendInfo.length - 1 ? `1px solid ${borderColor}` : `1px solid ${borderColor}` }}>{info.singlesend_name}</TableCell>
              </React.Fragment>
            ))}
            <TableCell align="center" colSpan={3} sx={{ fontWeight: 'bold' }}>Season</TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell> {/* New Subheader Cell */}
            {singlesendInfo.map((info, index) => (
              <React.Fragment key={info.singlesend_id}>
                <TableCell align="center" sx={{ fontWeight: 'bold', borderLeft: index === 0 ? `1px solid ${borderColor}` : 'none' }}>D</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>O</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: theme.palette.secondary.main, borderRight: index < singlesendInfo.length - 1 ? `1px solid ${borderColor}` : 'none' }}>C</TableCell>
              </React.Fragment>
            ))}
            <TableCell align="center" sx={{ fontWeight: 'bold', borderLeft: `1px solid ${borderColor}` }}>D</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>O</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold', color: theme.palette.secondary.main }}>C</TableCell>
          </TableRow>
        </TableHead>
          <TableBody>
            {groupedEmailEvents.map(([email, events], index) => (
              <TableRow key={email}>
                <Tooltip title={email} enterDelay={500} leaveDelay={200}>
                  <TableCell sx={{ borderRight: `1px solid ${borderColor}` }}>{email}</TableCell>
                </Tooltip>
                {/* Check if the value in the usernames map is an error message and apply styling */}
                <TableCell sx={{ borderRight: `1px solid ${borderColor}`, color: usernames[email] && (usernames[email].startsWith('No') || usernames[email] === 'User not found') ? theme.palette.grey[500] : 'inherit' }}>
                  {usernames[email]}
                </TableCell>
                {events.map((event, index) => (
                  <React.Fragment key={`${email}-${event.singlesend_id}`}>
                    <TableCell align="center">{event.events.delivered ? <div style={{ width: '10px', height: '10px', backgroundColor: 'black' }} /> : ''}</TableCell>
                    <TableCell align="center">{event.events.open ? <div style={{ width: '10px', height: '10px', backgroundColor: theme.palette.primary.main }} /> : ''}</TableCell>
                    <TableCell align="center" sx={{ borderRight: index < events.length - 1 ? `1px solid ${borderColor}` : 'none' }}>
                      {event.events.click ? <div style={{ width: '10px', height: '10px', backgroundColor: theme.palette.secondary.main }} /> : ''}
                    </TableCell>
                  </React.Fragment>
                ))}
                <TableCell align="center" sx={{ fontWeight: 'bold', borderLeft: `1px solid ${borderColor}` }}>{emailTotals[index].totalDelivered}</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>{emailTotals[index].totalOpened}</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: theme.palette.secondary.main }}>{emailTotals[index].totalClicked}</TableCell>
                </TableRow>
            ))}
            {/* Totals row */}
            <TableRow sx={{ fontWeight: 'bold' }}>
              <TableCell sx={{ borderRight: `1px solid ${borderColor}` }}>All Players</TableCell>
              <TableCell sx={{ borderRight: `1px solid ${borderColor}` }}></TableCell> {/* New Footer Cell */}
              {totalDelivered.map((delivered, index) => (
                <React.Fragment key={index}>
                  <TableCell align="center">{delivered}</TableCell>
                  <TableCell align="center" sx={{ color: theme.palette.primary.main }}>{totalOpened[index]}</TableCell>
                  <TableCell align="center" sx={{ color: theme.palette.secondary.main, borderRight: index < singlesendInfo.length - 1 ? `1px solid ${borderColor}` : 'none' }}>{totalClicked[index]}</TableCell>
                </React.Fragment>
              ))}
              <TableCell align="center" sx={{ borderLeft: `1px solid ${borderColor}` }}>{totalDelivered.reduce((sum, total) => sum + total, 0)}</TableCell>
              <TableCell align="center" sx={{ color: theme.palette.primary.main }}>{totalOpened.reduce((sum, total) => sum + total, 0)}</TableCell>
              <TableCell align="center" sx={{ color: theme.palette.secondary.main }}>{totalClicked.reduce((sum, total) => sum + total, 0)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EmailEngagement;
