// Import necessary functions and components from packages and local files
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { createRoot } from 'react-dom/client';  // The 'createRoot' function is for concurrent mode in React.
import App from './App';  // Importing the main App component.
import { AuthProvider } from './AuthContext';  // Importing the AuthProvider component which provides context for authentication.
import './index.css';  // Importing the CSS file for the app.
import CssBaseline from '@mui/material/CssBaseline';  // Importing the CSS Baseline component from MUI.

// Get the root element from the HTML where your app will be mounted.
const root = document.getElementById('root');

// If the root element is found in the DOM...
if (root !== null) {
    createRoot(root).render(
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthProvider>
                <App />
            </AuthProvider>
        </ThemeProvider>
    );
} else {
    console.error("Root element not found");
}

/* This is the root of your application. 
It's responsible for rendering your React app into the actual HTML of your page. 
It's also where you set up the context for your authentication state, 
which is provided to all components within your app. */