// SetPunnyTitle.js

import React, { useState, useEffect } from 'react';
import { TextField, Button, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import StoryHeader from './StoryHeader'; // Import StoryHeader

const SetPunnyTitle = () => {
  const [question, setQuestion] = useState('');
  const [questionId, setQuestionId] = useState('');
  const [status, setStatus] = useState('');
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState('');

  useEffect(() => {
    fetchQuestionOfTheDay();
  }, []);

  const fetchQuestionOfTheDay = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/current-voting-or-results-question`);
      setQuestionId(response.data._id);
      setStatus(response.data.status);
      setQuestion(response.data.content);
    } catch (error) {
      console.error('Error fetching question of the day:', error);
    }
  };

  useEffect(() => {
    if (questionId) {
      fetchAllStories(questionId); // Renamed to fetchAllStories
    }
  }, [questionId]);

  const fetchAllStories = async (questionId) => {
    try {
      // Updated the endpoint to /vote/all-stories
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/vote/all-stories/${questionId}`);
      // Sorting logic here
      const sortedStories = response.data.sort((a, b) => b.votes - a.votes || new Date(a.createdAt) - new Date(b.createdAt));
      setStories(sortedStories);
    } catch (error) {
      console.error('Error fetching stories:', error);
    }
  };

  const handleSubmit = async (storyId, punnyTitle) => {
    try {
      setLoading(true);
      const response = await axios.patch(`${process.env.REACT_APP_API_URL}/set-punny-title/${storyId}`, { punnyTitle });
      const updatedStory = response.data;
      setStories(stories.map(story => story._id === storyId ? {...story, ...updatedStory} : story));
      setLoading(false);
      setFeedback('Punny title updated successfully.');
      setOpen(true);
    } catch (error) {
      console.error('Error updating story:', error);
      setError('An error occurred while updating the story. Please try again.');
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Set Punny Title for "{question}"</h2>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      <Snackbar 
        open={open} 
        autoHideDuration={6000} 
        onClose={() => setOpen(false)}
      >
        <Alert 
          onClose={() => setOpen(false)} 
          severity='success'
          variant="filled"
        >
          {feedback}
        </Alert>
      </Snackbar>
      {stories.map((story, index) => (
        <div key={index}>
          <StoryHeader story={story} index={index} status={status} username={story.username} showRanking={true} />
          <p>{story.title}</p>
          <p>{story.content}</p> {/* Moved Content up */}
          <p>Votes: {story.votes}</p> {/* Moved Votes up */}
          <p>Submitted: {new Date(story.createdAt).toLocaleString("en-US", { timeZone: "America/New_York" })} ET</p> {/* Kept Submitted Date and Time */}
          <form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(story._id, e.target.punnyTitle.value);
          }}>
            <TextField label="Punny Title" variant="outlined" name="punnyTitle" defaultValue={story.punnyTitle || ''} />
            <Button type="submit" variant="contained">{story.punnyTitle ? 'Update Punny Title' : 'Set Punny Title'}</Button>
          </form>
        </div>
      ))}
    </div>
  );
};

export default SetPunnyTitle;
