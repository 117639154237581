// Feedback.js

import React, { useState, useEffect, useContext } from 'react';
import { Typography, Modal, Box, Card, CardContent, CircularProgress, Chip } from '@mui/material';
import axios from 'axios';
import NewFeedbackButton from './NewFeedbackButton';
import NewFeedback from './NewFeedback';
import CloseButton from './CloseButton';
import FeedbackSearch from './FeedbackSearch';  // <-- Import FeedbackSearch component
import { AuthContext } from './AuthContext';
import useCategory, { stringToColor } from './hooks/useCategory';
import _ from 'lodash';
import FeedbackUpvoteButton from './FeedbackUpvoteButton';
import Grid from '@mui/material/Grid';
import CustomSnackbar from './CustomSnackbar';

const Feedback = ({ open, handleClose }) => {
  const { currentUser } = useContext(AuthContext);
  const [feedbacks, setFeedbacks] = useState([]);
  const [filteredFeedbacks, setFilteredFeedbacks] = useState([]);  // <-- Add this
  const [loading, setLoading] = useState(true);
  const [isNewFeedbackOpen, setIsNewFeedbackOpen] = useState(false);
  const { category } = useCategory();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
   // Add this function to handle the snackbar after submitting a new feedback
   const handleNewFeedback = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/feedback`);

        // Separate the current page's feedbacks from the others
        const currentPageFeedbacks = response.data.filter(feedback => feedback.category === category);
        const otherPageFeedbacks = response.data.filter(feedback => feedback.category !== category);

        // Sort the feedback data
        const sortedCurrentPageFeedbacks = _(currentPageFeedbacks) // start chaining
          .groupBy('category') // group by category
          .map((feedbacks, category) => ({
            category,
            feedbacks: _.orderBy(feedbacks, ['upvotes', 'createdAt'], ['desc', 'desc']) // sort by upvotes (desc), then by createdAt (desc)
          }))
          .value(); // execute

        const sortedOtherPageFeedbacks = _(otherPageFeedbacks) // start chaining
          .groupBy('category') // group by category
          .map((feedbacks, category) => ({
            category,
            feedbacks: _.orderBy(feedbacks, ['upvotes', 'createdAt'], ['desc', 'desc']) // sort by upvotes (desc), then by createdAt (desc)
          }))
          .orderBy('category') // sort by category alphabetically (A-Z)
          .value(); // execute

        const sortedFeedbacks = [...sortedCurrentPageFeedbacks, ...sortedOtherPageFeedbacks];

        setFeedbacks(sortedFeedbacks);
        setFilteredFeedbacks(sortedFeedbacks);  // <-- Corrected this line
        setLoading(false);
      } catch (error) {
        console.error('Error fetching feedbacks:', error);
        setLoading(false);
      }
    };
    fetchFeedbacks();
  }, [open, category]); // add category to the dependency array

  // Add this function to handle closing the snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth
        maxWidth="md"
      >
        <Box 
          sx={{ 
            height: '100vh',
            overflowY: 'scroll',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h1>
              Feedback
            </h1>
            <CloseButton onClick={handleClose} />
          </Box>
          <FeedbackSearch feedbacks={feedbacks} setFilteredFeedbacks={setFilteredFeedbacks} />  {/* <-- Add this */}
          {loading ? (
            <CircularProgress />
          ) : (
            filteredFeedbacks.map(({ category, feedbacks }) => (
              <div key={category}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}> {/* Add a gap here */}
                  <h2>{category}</h2>
                  <NewFeedbackButton onClick={() => setIsNewFeedbackOpen(true)} />
                </Box>
                {feedbacks.map(feedback => {
                  const color = stringToColor(feedback.category);  // Use stringToColor function with feedback's category
                  return (
                    <Card key={feedback._id} sx={{ mt: 2 }}>
                      <CardContent>
                        <Grid container alignItems="center"> {/* Center items vertically */}
                          <Grid item xs={1} sm={1} md={1}> {/* Restrict the width of the upvote button */}
                            <FeedbackUpvoteButton id={feedback._id} initialUpvotes={feedback.upvotes} upvotedBy={feedback.upvotedBy} userId={currentUser.uid} />
                          </Grid>
                          <Grid item xs={11} sm={11} md={11} sx={{ paddingLeft: 3 }}> {/* Occupy the remaining space for feedback details and add left padding */}
                            <h3>
                              {feedback.title}{' '}
                              <Chip 
                                label={feedback.category} 
                                size="small" 
                                variant="outlined"
                                sx={{
                                  color: 'white',
                                  backgroundColor: color,  // Use the color from stringToColor
                                  borderColor: color,  // Use the color from stringToColor
                                  borderRadius: 0,
                                  fontSize: '0.8rem'
                                }}
                              />
                            </h3>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" mt={2}>
                              {feedback.description}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  );
                })}
              </div>
            ))
          )}
          <CustomSnackbar
            open={snackbarOpen}
            handleClose={handleCloseSnackbar}
            message={snackbarMessage}
            severity={snackbarSeverity}
          />
      </Box>
    </Modal>
    <NewFeedback open={isNewFeedbackOpen} handleClose={() => setIsNewFeedbackOpen(false)} handleNewFeedback={handleNewFeedback} />
  </>
);
};

export default Feedback;
