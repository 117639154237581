// CurrentGame.js

import React, { useState, useEffect } from 'react';
import SingleStory from './SingleStory';
import axios from 'axios';
import StoryHeader from './StoryHeader';
import VoteSubmittedMessage from './VoteSubmittedMessage';
import usePageContent from './hooks/usePageContent';

function CurrentGame() {
  const { content, loading: contentLoading } = usePageContent('CurrentGame');
  console.log('Content:', content);
  console.log('Content Loading:', contentLoading);
  const [stories, setStories] = useState([]);
  const [status, setStatus] = useState('');
  const [questionId, setQuestionId] = useState('');
  const [question, setQuestion] = useState('');
  const [voteSubmitted, setVoteSubmitted] = useState(false);
  const [votedStoryIds, setVotedStoryIds] = useState(JSON.parse(localStorage.getItem('votedStoryIds')) || []); // Changed from votedStoryId to votedStoryIds (array)
  const [usernames, setUsernames] = useState({});
  const [votesRemaining, setVotesRemaining] = useState(0);  // <-- New state for tracking remaining votes



  // New useEffect to fetch the allowed votes per story
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/settings`)
      .then(response => {
        setVotesRemaining(response.data.votesPerStory);
      })
      .catch(error => {
        console.error('Error fetching votes per story:', error);
      });
  }, []);
  
  useEffect(() => {
    // Clear the votedStoryIds from localStorage when question changes
    localStorage.removeItem('votedStoryIds');
    setVotedStoryIds([]); // Clear the array
  }, [questionId]);

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        // Fetch the question that is either in 'voting' or 'results' status
        const questionResponse = await axios.get(`${process.env.REACT_APP_API_URL}/current-voting-or-results-question`);
  
        // Get the questionId and status from the response
        setQuestionId(questionResponse.data._id);
        setStatus(questionResponse.data.status);
        setQuestion(questionResponse.data.content);
      } catch (error) {
        console.error('Error fetching question of the day:', error);
      }
    };
    fetchQuestion();
  }, []);

  useEffect(() => {
    const fetchStories = async () => {
      try {
        let fetchedStories = [];
        if (status === 'results') {
          const storiesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/vote/results/${questionId}`);
          fetchedStories = storiesResponse.data.map(item => ({ ...item.story, votes: item.votes, username: item.username }));
          console.log('Fetched results stories: ', fetchedStories);
        } else if (status === 'voting') {
          const storiesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/stories?questionId=${questionId}`);
          console.log('Fetched voting stories: ', storiesResponse.data);
          fetchedStories = storiesResponse.data;

          // Fetch usernames if status is 'voting'
          const uniqueUserIds = [...new Set(fetchedStories.map(story => story.userId))];
          Promise.all(uniqueUserIds.map(uid => 
            fetch(`${process.env.REACT_APP_API_URL}/users/${uid}`)
              .then(response => {
                // Only consider successful responses
                if (response.ok) {
                  return response.json()
                    .then(data => ({ uid, username: data.username }));
                } else {
                  console.warn(`Failed to fetch user ${uid}`);
                  return null;
                }
              })
          )).then(users => {
            const newUsernameMap = users.reduce((map, user) => {
              // Only add the user to the map if user data was fetched successfully
              if (user) {
                return { ...map, [user.uid]: user.username };
              } else {
                return map;
              }
            }, {});
            setUsernames(newUsernameMap);
            console.log('Updated usernames:', newUsernameMap);
          });
        }
        
        // Apply sorting logic here
        fetchedStories.sort((a, b) => b.votes - a.votes || new Date(a.createdAt) - new Date(b.createdAt));

        setStories(fetchedStories);
      } catch (error) {
        console.error('Error fetching stories:', error);
      }
    };

    // Only fetch stories if questionId and status are set
    if (questionId && status) {
      fetchStories();
    }
  }, [status, questionId]); // Add both status and questionId as dependencies

  if (contentLoading || !content) {
    // Replace this with a loading spinner or any other loading state if you prefer
    return <div>Loading...</div>;
  }
  
  // Function to check for ties
  const checkForTie = (storyIndex) => {
    if (stories[storyIndex].votes === stories[storyIndex + 1]?.votes) {
      return true;
    }
    return false;
  };
  
  return (
    <div>
      {voteSubmitted && votesRemaining === 0 ? ( // Only show VoteSubmittedMessage if votes are exhausted
        <VoteSubmittedMessage /> 
      ) : (
        <>
          <h1>{content.h1[0]}</h1>
          {status === 'voting' && <h2>Remaining Votes: {votesRemaining}</h2>}  {/* Display remaining votes only in voting mode */}
          {questionId ? (
            <>
              <h2>{question}</h2>
              {stories.map((story, index) => (
                <div key={story._id}>
                  <StoryHeader story={status === 'results' ? story : {...story, punnyTitle: ''}} index={index} status={status} username={status === 'results' ? story.username : usernames[story.userId]} />
                  <SingleStory 
                    story={story} 
                    votes={story.votes} 
                    status={status} 
                    setVoteSubmitted={setVoteSubmitted} 
                    votedStoryIds={votedStoryIds} // Pass the array
                    setVotedStoryIds={setVotedStoryIds} // Pass the setter function
                    isTie={status === 'results' && checkForTie(index)} // Pass the isTie prop
                    votesRemaining={votesRemaining}  // Pass remaining votes
                    setVotesRemaining={setVotesRemaining} // Pass the setter function
                  />
                </div>
              ))}
            </>
          ) : (
            <>
              {content.p.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default CurrentGame;
