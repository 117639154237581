import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';

const PlayerEngagement = () => {
  const [playersData, setPlayersData] = useState([]);
  const [questions, setQuestions] = useState([]);

  const theme = useTheme();
  const borderColor = theme.palette.divider;

  const fetchQuestions = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/questions/current-season`);
      const data = await response.json();
      setQuestions(data);
    } catch (err) {
      console.error('Error fetching questions:', err);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  useEffect(() => {
    const fetchPlayersData = async () => {
      try {
        if (questions.length === 0) return;

        const usersResponse = await fetch(`${process.env.REACT_APP_API_URL}/allusers`);
        const users = await usersResponse.json();
        
        // Fetch email addresses for all users
        const emailPromises = users.map(user =>
          fetch(`${process.env.REACT_APP_API_URL}/get-email-by-uid?uid=${user.uid}`)
            .then(res => res.json())
            .then(data => data.email)
        );
        const emails = await Promise.all(emailPromises);

        const storiesResponse = await fetch(`${process.env.REACT_APP_API_URL}/stories`);
        const stories = await storiesResponse.json();
        const votesResponse = await fetch(`${process.env.REACT_APP_API_URL}/vote/all-votes`);
        const votes = await votesResponse.json();

        const data = users.map((user, index) => {
          const engagement = new Array(questions.length).fill(0);
          const votesArray = new Array(questions.length).fill(0);

          stories.filter(story => story.userId === user.uid).forEach(story => {
            const question = questions.find(q => q._id === story.questionId.toString());
            if (question) {
              engagement[question.sequence - 1] = 1;
            }
          });

          votes.filter(vote => vote.userId === user.uid && vote.questionId && vote.storyId).forEach(vote => {
            const question = questions.find(q => q._id === vote.questionId._id.toString());
            if (question) {
              votesArray[question.sequence - 1] = 1;
            }
          });

          return {
            username: user.username,
            email: emails[index], // Add email address to the data
            engagement,
            votes: votesArray,
          };
        });

        setPlayersData(data);
      } catch (error) {
        console.error('Error fetching players:', error);
      }
    };

    fetchPlayersData();
  }, [questions]);

  // Calculate totals for each column
  const totalEngagement = Array(questions.length).fill(0);
  const totalVotes = Array(questions.length).fill(0);
  playersData.forEach((row) => {
    row.engagement.forEach((engagement, index) => {
      if (engagement) totalEngagement[index]++;
    });
    row.votes.forEach((vote, index) => {
      if (vote) totalVotes[index]++;
    });
  });

  // Calculate overall totals for each player
  const playerTotals = playersData.map(row => ({
    totalEngagement: row.engagement.reduce((sum, engagement) => sum + engagement, 0),
    totalVotes: row.votes.reduce((sum, vote) => sum + vote, 0),
  }));

  // Calculate grand totals for all players
  const grandTotalEngagement = playerTotals.reduce((sum, total) => sum + total.totalEngagement, 0);
  const grandTotalVotes = playerTotals.reduce((sum, total) => sum + total.totalVotes, 0);

  return (
    <>
      <h2>Player Engagement</h2>
      <div style={{ color: theme.palette.grey[500], fontSize: '0.875rem' }}>
        This shows events for players after they log into Parentball for the first time.<br/>
        Q# = the question number during the season. <br/>
        S = story submitted. V = vote submitted.
      </div>
      <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ borderRight: `1px solid ${borderColor}` }}>Username</TableCell>
              <TableCell sx={{ borderRight: `1px solid ${borderColor}` }}>Email Address</TableCell> {/* New header cell */}
              {questions.map((_, index) => (
                <TableCell align="center" colSpan={2} key={index} sx={{ borderRight: `1px solid ${borderColor}` }}>
                  Q{index + 1}
                </TableCell>
              ))}
              <TableCell align="center" colSpan={2} sx={{ borderRight: `1px solid ${borderColor}` }}>Season</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ borderRight: `1px solid ${borderColor}` }}></TableCell>
              <TableCell sx={{ borderRight: `1px solid ${borderColor}` }}></TableCell> {/* New subheader cell */}
              {Array.from({ length: questions.length * 2 }, (_, index) => (
                <TableCell key={index} align="center" sx={{ color: index % 2 !== 0 ? theme.palette.primary.main : 'inherit', borderRight: (index + 1) % 2 === 0 ? `1px solid ${borderColor}` : 'none', width: '5%' }}>
                  {index % 2 === 0 ? 'S' : 'V'}
                </TableCell>
              ))}
              <TableCell align="center" sx={{ width: '5%' }}>S</TableCell>
              <TableCell align="center" sx={{ color: theme.palette.primary.main, width: '5%' }}>V</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {playersData.map((row, index) => (
              <TableRow key={row.username}>
                <Tooltip title={row.email} enterDelay={500} leaveDelay={200}>
                  <TableCell sx={{ borderRight: `1px solid ${borderColor}`, width: '10%' }}>
                    {row.username}
                  </TableCell>
                </Tooltip>
                <TableCell sx={{ borderRight: `1px solid ${borderColor}`, width: '10%' }}>
                  {row.email} {/* Render email address */}
                </TableCell>
                {row.engagement.flatMap((engagement, index) => [
                  <TableCell key={`s-${index}`} align="center" sx={{ width: '5%' }}>
                    {engagement ? <div style={{ width: '10px', height: '10px', backgroundColor: 'black' }} /> : ''}
                  </TableCell>,
                  <TableCell key={`v-${index}`} align="center" sx={{ borderRight: (index + 1) < questions.length ? `1px solid ${borderColor}` : 'none', width: '5%' }}>
                    {row.votes && row.votes[index] ? <div style={{ width: '10px', height: '10px', backgroundColor: theme.palette.primary.main }} /> : ''}
                  </TableCell>,
                ])}
                <TableCell align="center" sx={{ fontWeight: 'bold', width: '5%' }}>{playerTotals[index].totalEngagement}</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold', color: theme.palette.primary.main, width: '5%' }}>{playerTotals[index].totalVotes}</TableCell>
              </TableRow>
            ))}
            {/* Totals row */}
            <TableRow sx={{ fontWeight: 'bold' }}>
              <TableCell sx={{ borderRight: `1px solid ${borderColor}`, width: '10%' }}>All Players</TableCell>
              <TableCell sx={{ borderRight: `1px solid ${borderColor}`, width: '10%' }}></TableCell> {/* New footer cell */}
              {totalEngagement.flatMap((engagement, index) => [
                <TableCell key={`ts-${index}`} align="center" sx={{ width: '5%' }}>
                  {engagement}
                </TableCell>,
                <TableCell key={`tv-${index}`} align="center" sx={{ color: theme.palette.primary.main, borderRight: (index + 1) < questions.length ? `1px solid ${borderColor}` : 'none', width: '5%' }}>
                  {totalVotes[index]}
                </TableCell>,
              ])}
              <TableCell align="center" sx={{ fontWeight: 'bold', width: '5%' }}>{grandTotalEngagement}</TableCell>
              <TableCell align="center" sx={{ fontWeight: 'bold', color: theme.palette.primary.main, width: '5%' }}>{grandTotalVotes}</TableCell>
            </TableRow>
          </TableBody>
          </Table>
        </TableContainer>
    </>
  );
};

export default PlayerEngagement;
