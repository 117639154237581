import React from 'react';
import usePageContent from './hooks/usePageContent';

function PrivacyPolicy() {
  const { content, loading } = usePageContent('PrivacyPolicy');
  
  if (loading || !content) {
    return <p></p>;
  }
  
  return (
    <div dangerouslySetInnerHTML={{ __html: content.div }} />
  );
}

export default PrivacyPolicy;
